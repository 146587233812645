export function saveObjToLocal(title, value) {
	localStorage.setItem('_' + title, JSON.stringify(value));
}

export function removeSingleItemFromLocal(title) {
	localStorage.removeItem('_' + title);
}

export function getObjFromLocal(objTitle) {
	let obj = null;

	try {
		const storedData = localStorage.getItem('_' + objTitle);

		if (storedData) {
			obj = JSON.parse(storedData);
		}
	} catch (err) {
		// If stored data is not a strigified JSON this might fail,
		// that's why we catch the error
	}

	return obj;
}

export function cleanLocalStorage() {
	for (var k in localStorage) {
		if (k.startsWith('_')) {
			localStorage.removeItem(k);
		}
	}
}

export function isEmptyObject(obj) {
	if (obj === undefined || obj === null) return true;
	else return Boolean(Object.keys(obj).length === 0);
}

export function isEmptyList(obj) {
	if (obj === undefined || obj === null) return true;
	else return Boolean(obj.length === 0);
}

export function isEmptyString(string) {
	return Boolean(string === undefined || string === null || string === '');
}


export function getValueAssetState(list,key){
    var obj = list?.filter(as=> as?.key === key);
    if(obj && obj?.length > 0){
      return obj[0]?.value;
    }
  }

  export function getDomainName()
{
	let hostName = window.location.hostname;
    return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1));
}