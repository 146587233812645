export const TRANSLATE = {
	/*  TOPBAR  */
	APPLICATIONS:'APPLICATIONS',
	LANGUAGE:'LANGUAGE',
	ITALIAN_:'ITALIAN_',
  	ENGLISH_:'ENGLISH_',
	SAVE:'SAVE',
	SETTINGS:'SETTINGS',
	THEME:'THEME',
	LIGHT_DEFAULT_:'LIGHT_DEFAULT_',
	DARK_:'DARK_',
	EDIT_ACCOUNT:'EDIT_ACCOUNT',
	LOGOUT:'LOGOUT',    
	HISTORY_DOWNLOAD_:'HISTORY_DOWNLOAD_',
	PROGRESS:'PROGRESS',
	END:'END',
	NO_NOTIFY:'NO_NOTIFY',
	NOTIFY:'NOTIFY',
	REMOVE_ALL_DOWNLOAD:'REMOVE_ALL_DOWNLOAD',
	SCHEDULED_:'SCHEDULED_',
	STOPPED:'STOPPED',
	DELETED:'DELETED',
	ERROR_OCCURRED:'ERROR_OCCURRED',

	/*  NAVBAR  */
	HOME:'HOME',
	DASHBOARD:'DASHBOARD',
	ELECTRIC_VEHICLES:'ELECTRIC_VEHICLES',
	VEHICLES:'VEHICLES',
	ALARMS:'ALARMS',
	TRIPS:'TRIPS',
	CHARGE_SESSIONS:'CHARGE_SESSIONS',
	DIAGNOSTICS:'DIAGNOSTICS',
	EVENTS:'EVENTS',
	MANAGEMENT:'MANAGEMENT',
	FIRMWARE_REPO:'FIRMWARE_REPO',
	SOFTWARE_REPO:'SOFTWARE_REPO',
	CONFIGURATION_REPO:'CONFIGURATION_REPO',
	MEASURE_GROUPS:'MEASURE_GROUPS',
	MEASURES:'MEASURES',
	ASSOCIATIONS:'ASSOCIATIONS',
    ACCESS:'ACCESS',
	FLEETS:'FLEETS',
	ASSET_TYPES:'ASSET_TYPES',
	ASSETS:'ASSETS',
	ACCESSES:'ACCESSES',
	USERS:'USERS',
	PERMISSION:'PERMISSION',
	CONFIGURATIONS:'CONFIGURATIONS',
	CONFIGURATION:'CONFIGURATION',
	TENANT:'TENANT',
	WORKFLOW_TYPE:'WORKFLOW_TYPE',
	WORKSPACE:'WORKSPACE',
	WORKSPACE_TYPE:'WORKSPACE_TYPE',
	DEVELOP:'DEVELOP',
	STORYBOARD:'STORYBOARD',
	STORYBOARD_MIGRATION:'STORYBOARD_MIGRATION',
	ADMINISTRATION:'ADMINISTRATION',
	CONSOLE_PYTHON:'CONSOLE_PYTHON',
	SYSTEM:'SYSTEM',
	LIST:'LIST',
	ACTIVITIES:'ACTIVITIES',
	SALES_ORDER:'SALES_ORDER',
	SALES_ORDER_STATUS:'SALES_ORDER_STATUS',
	INSERT_ORDER:'INSERT_ORDER',
	SPARE_PARTS:'SPARE_PARTS',
    BUSINESS_PARTNER:'BUSINESS_PARTNER',
	MAINTENANCES:'MAINTENANCES',
	IMPORTER:'IMPORTER',
	DEALER:'DEALER',
	IMPORTER_PARENT:'IMPORTER_PARENT',
	DEALER_PARENT:'DEALER_PARENT',
	CUSTOMER:'CUSTOMER',
	WORKSHOP:'WORKSHOP',
	IMPORTERS:'IMPORTERS',
	SUPPLIER:'SUPPLIER',
	DEALERS:'DEALERS',
	SUBDEALERS:'SUBDEALERS',
	CUSTOMERS:'CUSTOMERS',
	WORKSHOPS:'WORKSHOPS',
	SUPPLIERS:'SUPPLIERS',
	TICKETS:'TICKETS',
	SERVICE_ORDERS:'SERVICE_ORDERS',
	SERVICE_REQUESTS:'SERVICE_REQUESTS',  

	/*  TABLE  */
	NO_RESULTS_FOUND:'NO_RESULTS_FOUND',
    NO_RESULT_FOUNDED:'NO_RESULT_FOUND',
	SEARCH_RESULTS:'SEARCH_RESULTS',
	SORT:'SORT',
	FILTERS:'FILTERS',
	SEARCH:'SEARCH',
	CONTAINS:'CONTAINS',
	CONTAINS_:'CONTAINS_',
	GREATER_THAN_OR_EQUAL:'GREATER_THAN_OR_EQUAL',
	LESS_THAN_OR_EQUAL:'LESS_THAN_OR_EQUAL',
	EQUAL:'EQUAL',
	STARTS_WITH:'STARTS_WITH',
	STARTS_WITH_:'STARTS_WITH_',
	FROM:'FROM',
	TO:'TO',
	DATE:'DATE',
	DESC:'DESC',
	ASC:'ASC',
	NAME_AZ:'NAME_AZ',
	NAME_ZA:'NAME_ZA',
	DESCRIPTION_AZ:'DESCRIPTION_AZ',
	DESCRIPTION_ZA:'DESCRIPTION_ZA',
	NEW:'NEW',
	LAST_CHANGE_NEW:'LAST_CHANGE_NEW',
	LAST_CHANGE_OLD:'LAST_CHANGE_OLD',
	UNIT:'UNIT',
	CREATION_NEW:'CREATION_NEW',
	CREATION_OLD:'CREATION_OLD',
	EXPIRATION_NEW:'EXPIRATION_NEW',
	EXPIRATION_OLD:'EXPIRATION_OLD',
	LAST_UPDATE_NEW:'LAST_UPDATE_NEW',
	LAST_UPDATE_OLD:'LAST_UPDATE_OLD',
	BACK_TO:'BACK_TO',

	// FILTER
	FROM_TRIP:'FROM_TRIP',
	TO_TRIP:'TO_TRIP',
	FROM_CHARGE:'FROM_CHARGE',
	TO_CHARGE:'TO_CHARGE',
	NAME:'NAME',
	GROUP:'GROUP',
	SYSTEM_ADMINISTRATOR:'SYSTEM_ADMINISTRATOR',
	SYSTEM_SUPERVISOR:'SYSTEM_SUPERVISOR',
	AGENCY_ROLE:'AGENCY_ROLE',
	AGENT_ROLE:'AGENT_ROLE',
	BACKOFFICE_ROLE:'BACKOFFICE_ROLE',
	STATUS:'STATUS',
	STATUS_CONNECTION:'STATUS_CONNECTION',
	LAST_UPDATE:'LAST_UPDATE',
	ACTIVE_:'ACTIVE_',
	INACTIVE_:'INACTIVE_',
	OPEN_:'OPEN_',
	CLOSE_:'CLOSE_',
	DRAFT_:'DRAFT_',
	ASSIGNMENT:'ASSIGNMENT',
	ASSIGNED_TO_ME:'ASSIGNED_TO_ME',
	ALL_ASSIGNMENTS:'ALL_ASSIGNMENTS',
	LAST_NAME_ASSIGNEE:'LAST_NAME_ASSIGNEE',
	NAME_ASSIGNEE:'NAME_ASSIGNEE',
	LAST_NAME_REPORTER:'LAST_NAME_REPORTER',
	NAME_REPORTER:'NAME_REPORTER',
	ISSUED_DEPARTMENT:'ISSUED_DEPARTMENT',
	ASSIGNED_DEPARTMENT:'ASSIGNED_DEPARTMENT',
	CREATION_DATE:'CREATION_DATE',
	EXPIRY_DATE:'EXPIRY_DATE',
	MODIFICATION_DATE:'MODIFICATION_DATE',
	TYPOLOGY:'TYPOLOGY',
	CATEGORY:'CATEGORY',
	TICKET_NUMBER:'TICKET_NUMBER',
	PROCESSING_STATUS:'PROCESSING_STATUS',
	TO_WORK:'TO_WORK',
	TAKEN_IN_CHARGE:'TAKEN_IN_CHARGE',
	ALL:'ALL',
	STATUS_OPEN:'STATUS_OPEN',
	COMPLETED_:'COMPLETED_',
	TITLE:'TITLE',
	DEPARTMENT_NAME:'DEPARTMENT_NAME',
	TRUE:'TRUE',
	FALSE:'FALSE',
	EXPIRED:'EXPIRED',
	SERIAL_NUMBER:'SERIAL_NUMBER',

	/*  HOME  */

	// DASHBOARD
	OVERVOLTAGE_:'OVERVOLTAGE_',
	LOI_:'LOI_',
	UNDERVOLTAGE_:'UNDERVOLTAGE_',
	TO_BE_RESET:'TO_BE_RESET',
	INTERMITTENT:'INTERMITTENT',
	PRESENT_THIS_CYCLE:'PRESENT_THIS_CYCLE',
	PRESENT:'PRESENT',
	ACTIVE_VEHICLES_:'ACTIVE_VEHICLES_',
	CHARGE_SESSIONS_:'CHARGE_SESSIONS_',
	TRAVELED_DISTANCE_:'TRAVELED_DISTANCE_',
	INVERTER_ISSUES_:'INVERTER_ISSUES_',
	TOTAL_VEHICLES:'TOTAL_VEHICLES',
	TOTAL_CHARGE_SESSIONS:'TOTAL_CHARGE_SESSIONS',
	TOTAL_TRAVELED_DISTANCE:'TOTAL_TRAVELED_DISTANCE',
	TOTAL_ISSUES:'TOTAL_ISSUES',
	GOOD:'GOOD',
	MEDIUM:'MEDIUM',
	BAD:'BAD',
	TRIP:'TRIP',
	PARKED:'PARKED',
	CHARGING:'CHARGING',
	MAINTENANCE:'MAINTENANCE',
	MAINTENANCE_NUMBER:'MAINTENANCE_NUMBER',

	/*  ELECRTIC VEHICLES  */

	// VEHICLES
	TOTAL_KM:'TOTAL_KM',
	AVG_CURRENT:'AVG_CURRENT',
	SERVICE_VOLTAGE:'SERVICE_VOLTAGE',
	AVAILABLE_KM:'AVAILABLE_KM',
	CONNECT:'CONNECT',
	NOT_CONNECT:'NOT_CONNECT',
	NO_ERROR:'NO_ERROR',
	MAP:'MAP',
	LOADING:'LOADING',
	OVERVIEW:'OVERVIEW',
	DASHBOARD_MEASURES:'DASHBOARD_MEASURES',
	ASSET_ASSOCIATION:'ASSET_ASSOCIATION',
	ASSET_INFORMATION:'ASSET_INFORMATION',
	NUMBER_OF_BATTERIES:'NUMBER_OF_BATTERIES',
	SPEEDOMETER_TOLERANCE:'SPEEDOMETER_TOLERANCE',
	QUIET_VEHICLE_PROTECTION_MODULE:'QUIET_VEHICLE_PROTECTION_MODULE',
	OBC_TYPE:'OBC_TYPE',
	AVG_CONSUMPTION:'AVG_CONSUMPTION',
	AVAILABLE_RANGE:'AVAILABLE_RANGE',
	SERVICE_BATTERY:'SERVICE_BATTERY',
	VEHICLE_STATE:'VEHICLE_STATE',
	UNDERVOLTAGE:'UNDERVOLTAGE',
	OVERVOLTAGE:'OVERVOLTAGE',
	TURTLE_MODE:'TURTLE_MODE',
	STEERING_FAULT:'STEERING_FAULT',
	EV_WARNING:'EV_WARNING',
	MOTOR_FAULT:'MOTOR_FAULT',
	GENERIC_FAULT:'GENERIC_FAULT',
	HV_BATTERY_FAULT:'HV_BATTERY_FAULT',
	LOW_BATTERY:'LOW_BATTERY',
	VEHICLE_STATISTICS:'VEHICLE_STATISTICS',
	AGE:'AGE',
	MEASURE_ERROR:'MEASURE_ERROR',
	DATE_ERROR:'DATE_ERROR',
	NA:'NA',
	CHARGED:'CHARGED',
	ACTIVE_ALARMS:'ACTIVE_ALARMS',
	VALUE:'VALUE',
	DASHBOARD_ERROR:'DASHBOARD_ERROR',
    START_DATE_:'START_DATE_',
	END_DATE_:'END_DATE_',
	APPLY_:'APPLY_',
	CLEAR_:'CLEAR_',
    // without "_" at the end of the value
	START_DATE_:'START_DATE',
    END_DATE_:'END_DATE',
    APPLY_:'APPLY',
    CLEAR_:'CLEAR',
	LAST_24H:'LAST_24H',

	// ALARMS
	SHOWING:'SHOWING',
	_ALARMS:'_ALARMS',
	CLOSE:'CLOSE',
	OPEN:'OPEN',
	CLOSED:'CLOSED',
	SEVERITY:'SEVERITY',
	CRITICAL:'CRITICAL',
	LOW:'LOW',
	DEVICE:'DEVICE',

	// TRIPS
	FROM_TO_TRIP:'FROM_TO_TRIP',
	CONSUMED_SOC:'CONSUMED_SOC',
	CONSUMPTION:'CONSUMPTION',
	SPEED_MAX:'SPEED_MAX',
	DISTANCE:'DISTANCE',
	DURATION_HOURS:'DURATION_HOURS',

	// CHARGE SESSIONS
	FROM_TO_CHARGE:'FROM_TO_CHARGE',
	DURATION:'DURATION',
	SOC_START:'SOC_START',
	SOC_END:'SOC_END',
    SOC_START_END:'SOC_START_END',
	FAST_CHARGE:'FAST_CHARGE',
	CHARGE_TYPE:'CHARGE_TYPE',
	CHARGE_MODE:'CHARGE_MODE',
	TOTAL_CHARGED:'TOTAL_CHARGED',
	CHARGED_SOC:'CHARGED_SOC',
	RECHAGED_ENERGY:'RECHAGED_ENERGY',
	JANUARY:'JANUARY',
	FEBRUARY:'FEBRUARY',
	MARCH:'MARCH',
	APRIL:'APRIL',
	MAY:'MAY',
	JUNE:'JUNE',
	JULY:'JULY',
	AUGUST:'AUGUST',
	SEPTEMBER:'SEPTEMBER',
	OCTOBER:'OCTOBER',
	NOVEMBER:'NOVEMBER',
	DECEMBER:'DECEMBER',

	// DIAGNOSTICS
	COMPONENT:'COMPONENT',
	TRACEABILITY_ID:'TRACEABILITY_ID',

	// EVENTS
	_EVENTS:'_EVENTS',
	SUCCESSFUL:'SUCCESSFULL',
	FAILED:'FAILED',

	/*  MANAGEMENT  */

	// MEASURE GROUPS
	MEASURE_GROUP_DELETED:'MEASURE_GROUP_DELETED',
	ENABLED:'ENABLED',
	NOT_ENABLED:'NOT_ENABLED',
	ACTIONS:'ACTIONS',
	DELETE:'DELETE',
	DELETE_MEASURE_GROUP:'DELETE_MEASURE_GROUP',
	MEASURE_GROUP_DIALOG:'MEASURE_GROUP_DIALOG',
	NEW_MEASURE_GROUP:'NEW_MEASURE_GROUP',
	EDIT_MEASURE_GROUP:'EDIT_MEASURE_GROUP',
	WARNING:'WARNING',
	DESCRIPTION_REQUIRED:'DESCRIPTION_REQUIRED',
	ENABLED_REQUIRED:'ENABLED_REQUIRED',
	MEASURE_GROUP_CHANGED:'MEASURE_GROUP_CHANGED',
	MEASURE_GROUP_DATA:'MEASURE_GROUP_DATA',
	MEASURE_GROUP_ADDED:'MEASURE_GROUP_ADDED',

	// MEASURES
	MEASURE_DELETED:'MEASURE_DELETED',
	CODE:'CODE',
	TYPE:'TYPE',
	DELETE_MEASURE:'DELETE_MEASURE',
	MEASURE_DIALOG:'MEASURE_DIALOG',
	NEW_MEASURE:'NEW_MEASURE',
	EDIT_MEASURE:'EDIT_MEASURE',
	METRICS:'METRICS',
	PROPERTIES:'PROPERTIES',
	CODE_REQUIRED:'CODE_REQUIRED',
	TYPE_REQUIRED:'TYPE_REQUIRED',
	MEASURE_CHANGED:'MEASURE_CHANGED',
	MEASURE_DATA:'MEASURE_DATA',
	MEASURE_ADDED:'MEASURE_ADDED',
	INTEGER:'INTEGER',
	CHOOSE_ICON:'CHOOSE_ICON',
	UPDATE_ICON:'UPDATE_ICON',

	// DASBOARD TEMPLATE
	TEMPLATE_DASHBOARD:'TEMPLATE_DASHBOARD',
	SAVED_DONE:'SAVED_DONE',
	DELETE_ENUM:'DELETE_ENUM',
	DELETE_ENUM_DIALOG:'DELETE_ENUM_DIALOG',
	ENUM_ASSOCIATED:"ENUM_ASSOCIATED",
	ENUM_DELETED:"ENUM_DELETED",

	// ASSOCIATIONS
	SHOWN_DASHBOARD:'SHOWN_DASHBOARD',
	NOT_SHOWN_DASHBOARD:'NOT_SHOWN_DASHBOARD',
	EDIT:'EDIT',
	EDIT_ASSOCIATION:'EDIT_ASSOCIATION',
	ASSOCIATION_DELETED:'ASSOCIATION_DELETED',
	ASSET_TYPE_REQUIRED:'ASSET_TYPE_REQUIRED',
	MEASURE_GROUP_REQUIRED:'MEASURE_GROUP_REQUIRED',
	MEASURE_REQUIRED:'MEASURE_REQUIRED',
	ASSOCIATION_ADDED:'ASSOCIATION_ADDED',
	ASSOCIATION_DATA:'ASSOCIATION_DATA',
	DELETE_ASSOCIATION:'DELETE_ASSOCIATION',
	ASSOCIATION_DIALOG:'ASSOCIATION_DIALOG',
	ASSOCIATED_MEASURES:'ASSOCIATED_MEASURES',
	ASSOCIATE:'ASSOCIATE',
	MEASURE_GROUP:'MEASURE_GROUP',
	MEASURE:'MEASURE',
	NEW_ASSOCIATION:'NEW_ASSOCIATION',
	ASSOCIATION_CHANGED:'ASSOCIATION_CHANGED',

	// FLEET
	FLEET:'FLEET',
	FLEET_DELETED:'FLEET_DELETED',
	DELETE_FLEET:'DELETE_FLEET',
	FLEET_DIALOG:'FLEET_DIALOG',
	FLEET_DATA:'FLEET_DATA',
	
	// ASSET TYPE
	ASSET_TYPE:'ASSET_TYPE',
	ASSET_TYPE_DATA:'ASSET_TYPE_DATA',
	ASSET_TYPE_DELETED:'ASSET_TYPE_DELETED',
	DELETE_ASSET_TYPE:'DELETE_ASSET_TYPE',
	ASSET_TYPE_DIALOG:'ASSET_TYPE_DIALOG',

	// ASSET
	ASSET:'ASSET',
	LATITUDE:'LATITUDE',
	LONGITUDE:'LONGITUDE',
	ASSET_DATA:'ASSET_DATA',
	ASSET_DELETED:'ASSET_DELETED',
	DELETE_ASSET:'DELETE_ASSET',
	ASSET_DIALOG:'ASSET_DIALOG',

	// USER ASSOCIATION
	ASSET_REQUIRED:'ASSET_REQUIRED',
	USER_ASSOCIATION:'USER_ASSOCIATION',
	NEW_USER_ASSOCIATION:'NEW_USER_ASSOCIATION',
	USER_REQUIRED:'USER_REQUIRED',
	USER_ASSOCIATION_DATA:'USER_ASSOCIATION_DATA',

	/*  ACCESSES  */

	// USERS
	USER_LIST:'USER_LIST',
	USER:'USER',
	INACTIVE:'INACTIVE',
	NEW_USER:'NEW_USER',
	USER_CREATED:'USER_CREATED',
	ROLE_DESCRIPTION:'ROLE_DESCRIPTION',    
	USER_SETTINGS:'USER_SETTINGS',
	PROFILE_INFO:'PROFILE_INFO',
	GENERAL:'GENERAL',
	NOTIFICATIONS:'NOTIFICATIONS',
	FUNCTIONS:'FUNCTIONS',
	PERMITS:'PERMITS',
	ROLES:'ROLES',
	PERMISSION_SUMMARY:'PERMISSION_SUMMARY',
	ATTENTION:'ATTENTION',
	PROFILE_DIALOG:'PROFILE_DIALOG',
	PROFILE_DIALOG2:'PROFILE_DIALOG2',
	PROCEED:'PROCEED',
	USER_DATA:'USER_DATA',
	GENERAL_ERROR:'GENERAL_ERROR',
	FIELD_REQUIRED:'FIELD_REQUIRED',
	USERNAME_REQUIRED:'USERNAME_REQUIRED',
	EMAIL_ERROR:'EMAIL_ERROR',
	EMAIL_REQUIRED:'EMAIL_REQUIRED',
	EMAIL:'EMAIL',
	SECONDARY_EMAIL:'SECONDARY_EMAIL',
	FIRST_NAME:'FIRST_NAME',
	FIRST_NAME_REQUIRED:'FIRST_NAME_REQUIRED',
	LAST_NAME:'LAST_NAME',
	LAST_NAME_REQUIRED:'LAST_NAME_REQUIRED',
	VISUALIZED_NAME:'VISUALIZED_NAME',
	VISUALIZED_NAME_REQUIRED:'VISUALIZED_NAME_REQUIRED',
	ROLE:'ROLE',
	PHONE:'PHONE',
	SECONDARY_PHONE:'SECONDARY_PHONE',
	NOTE:'NOTE',
	ACTIVE:'ACTIVE',
	CREATE:'CREATE',
	USER_CHANGED:'USER_CHANGED',
	RESET_PASSWORD:'RESET_PASSWORD',
	NEW_PASSWORD:'NEW_PASSWORD',
	PASSWORD_REQUIRED:'PASSWORD_REQUIRED',
	CONFIRM_PASSWORD:'CONFIRM_PASSWORD',
	PASSWORD_ERROR:'PASSWORD_ERROR',
	CHANGE_PASSWORD:'CHANGE_PASSWORD',
	PASSWORD_CHANGED:'PASSWORD_CHANGED',
	SUBMIT_RESET_PASSWORD:'SUBMIT_RESET_PASSWORD',
	PASSWORD_DIALOG:'PASSWORD_DIALOG',
	PASSWORD_DIALOG2:'PASSWORD_DIALOG2',
	CONFIRM:'CONFIRM',
	CANCEL:'CANCEL',
	NOTIFICATIONS_:'NOTIFICATIONS_',
	REMOVE:'REMOVE',
	NOTIFICATION_CHANGED:'NOTIFICATION_CHANGED',
	NOTIFICATIONS_INFO:'NOTIFICATIONS_INFO',
	ADD_TENANT:'ADD_TENANT',
	TENANT_INFO:'TENANT_INFO',
	TENANT_CHANGED:'TENANT_CHANGED',
	ADD:'ADD',
	TENANT_LIST:'TENANT_LIST',
	TENANT_INFO2:'TENANT_INFO2',
	NO_TENANT:'NO_TENANT',
	EXTENSION_LIST:'EXTENSION_LIST',
	TENANT_INFO3:'TENANT_INFO3',
	NO_EXTENSION:'NO_EXTENSION',
	PROPERTY_LIST:'PROPERTY_LIST',
	TENANT_INFO4:'TENANT_INFO4',
	NO_PROPERTY:'NO_PROPERTY',
	DELETE_TENANT:'DELETE_TENANT',
	TENANT_DIALOG:'TENANT_DIALOG',
	DELETE_PROFILE:'DELETE_PROFILE',
	TENANT_DIALOG2:'TENANT_DIALOG2',
	DELETE_PROPERTY:'DELETE_PROPERTY',
	TENANT_DIALOG3:'TENANT_DIALOG3',
	BUSINESS_PARTNER:'BUSINESS_PARTNER',
	CRM:'CRM',
	ADD_FUNCTION:'ADD_FUNCTION',
	FUNCTION_INFO:'FUNCTION_INFO',
	GROUP_CHANGED:'GROUP_CHANGED',
	FUNCTION:'FUNCTION',
	FUNCTION_LIST:'FUNCTION_LIST',
	FUNCTION_INFO2:'FUNCTION_INFO2',
	NO_FUNCTION:'NO_FUNCTION',
	DELETE_FUNCTION:'DELETE_FUNCTION',
	FUNCTION_DIALOG:'FUNCTION_DIALOG',
	PERMITS_INFO:'PERMITS_INFO',
	ROLES_INFO:'ROLES_INFO',
	GROUPS_:'GROUPS_',
	GROUPS:'GROUPS',
	FUNCTION_TAG:'FUNCTION_TAG',
	DESCRIPTION:'DESCRIPTION',
	KEY:'KEY',
	CREATE_:'CREATE_',
	READ_:'READ_',
	UPDATE_:'UPDATE_',
	DELETE_:'DELETE_',
	YES_:'YES_',
	NO_:'NO_',
	FUNCTION_TAG2:'FUNCTION_TAG2',
	GROUPS_INFO:'GROUPS_INFO',

	// PERMISSION
	PERMITS_LIST:'PERMITS_LIST',
	NEW_GROUP:'NEW_GROUP',
	GROUPID_REQUIRED:'GROUPID_REQUIRED',
	GROUPS_ADDED:'GROUP_ADDED',
	GROUP_DATA:'GROUP_DATA',
	GROUP_ID:'GROUP_ID',
	ASSOCIATED_USERS:'ASSOCIATED_USERS',
	ASSOCIATED_PERMITS:'ASSOCIATED PERMITS',
	NO_ASSOCIATED_USER:'NO_ASSOCIATED_USER',
	NO_PERMITS_ASSOCIATED:'NO_PERMITS_ASSOCIATED',

	// CONFIGURATIONS
	CONFIGURATION_LIST:'CONFIGURATION_LIST',
	VIEW:'VIEW',
	NO_TAG_ASSOCIATED:'NO_TAG_ASSOCIATED',
	IMPORT:'IMPORT',
	IMPORT_:'IMPORT_',
	CONFIRM_:'CONFIRM_',
	CANCEL_:'CANCEL_',
	UPDATE_COMPLETED:'UPDATE_COMPLETED',
    TAGS_ASSOCIATED:'TAG_ASSOCIATED',
	TAG_ASSOCIATED:'TAG_ASSOCIATED',
	DOCUMENT_ERROR:'DOCUMENT_ERROR',
	FILE_ERROR:'FILE_ERROR',
	DOCUMENT_DELETED:'DOCUMENT_DELETED',
	DOCUMENT_ERROR2:'DOCUMENT_ERROR2',
	ATTACHMENTS:'ATTACHMENTS',
	ADD_ATTACHMENTS:'ADD_ATTACHMENTS',
	REMOVE_FILE:'REMOVE_FILE',
	FILE_DIALOG:'FILE_DIALOG',
	DELETE_FILE: 'DELETE_FILE',
    DELETE_FILE_REQUEST: 'DELETE_FILE_REQUEST',

	/*  CONFIGURATION  */

	// TENANT
	CHANGE:'CHANGE',
	CHANGE_TENANT:'CHANGE_TENANT',
	NEW_TENANT:'NEW_TENANT',
	IMAGE_UPLOADED:'IMAGE_UPLOADED',
	TENANT_SAVED:'TENANT_SAVED',
	TENANT_NAME:'TENANT_NAME',
	ADMIN_USER:'ADMIN_USER',
	USER_PASSWORD:'USER_PASSWORD',
	VIEW_IMAGE:'VIEW_IMAGE',
	UPLOAD_IMAGE:'UPLOAD_IMAGE',

	// WORKFLOW TYPE
	WORKFLOW_TYPE_LIST:'WORKFLOW_TYPE_LIST',
	WORKFLOW_DELETED:'WORKFLOW_DELETED',
	DELETE_WORKFLOW:'DELETE_WORKFLOW',
	CONFIGURATION_DIALOG:'CONFIGURATION_DIALOG',
	CHANGE_WORKFLOW_TYPE:'CHANGE_WORKFLOW_TYPE',
	NEW_WORKFLOW_TYPE:'NEW_WORKFLOW_TYPE',
	WORKFLOW_TYPE_SAVED:'WORKFLOW_TYPE_SAVED',
	WORKFLOW_TYPE_NAME:'WORKFLOW_TYPE_NAME',
	MANDATORY_CUSTOMER:'MANDATORY_CUSTOMER',
	MANDATORY_SUPPLY:'MANDATORY_SUPPLY',
	HOURS_TO_DEADLINE:'HOURS_TO_DEADLINE',

	/*  WORKSPACE  */

	// WORKSPACE
	WORKSPACE_LIST:'WORKSPACE_LIST',
	ASSIGNED_TO:'ASSIGNED_TO',
	TYPE_:'TYPE_',
	ACTIVE_USER:'ACTIVE_USER',
	WORKSPACE_DELETED:'WORKSPACE_DELETED',
	DELETE_WORKSPACE:'DELETE_WORKSPACE',
	WORKSPACE_DIALOG:'WORKSPACE_DIALOG',
	WORKSPACE_SAVED:'WORKSPACE_SAVED',
	WORKSPACE_NAME:'WORKSPACE_NAME',
	ASSIGNED:'ASSIGNED',
	MAILBOX_NOTIFICATIONS:'MAILBOX_NOTIFICATIONS',
	PERMIT_DELETED:'PERMITS_DELETED',
	ACTIVE_PERMITS:'ACTIVE_PERMITS',
	ASSOCIATE_PERMIT:'ASSOCIATE_PERMIT',
	PERMIT:'PERMIT',
	ASSOCIATED_ON:'ASSOCIATED_ON',
	NO_PERMIT_FOUNDED:'NO_PERMIT_FOUNDED',
	DELETE_PERMIT:'DELETE_PERMIT',
	DETAIL_DIALOG:'DETAIL_DIALOG',
	PERMIT_SAVED:'PERMIT_SAVED',
	ENTER_USER:'ENTER_USER',
	ACTIVE_USERS:'ACTIVE_USERS',
	ASSOCIATE_USER:'ASSOCIATE_USER',
	NO_USER_FOUNDED:'NO_USER_FOUNDED',
	DELETE_USER:'DELETE_USER',
	DETAIL_DIALOG2:'DETAIL_DIALOG2',
	USER_SAVED:'USER_SAVED',
	MAILBOX_DELETED:'MAILBOX_DELETED',
	ACTIVE_MAILBOXES:'ACTIVE_MAILBOXES',
	ASSOCIATE_MAILBOX:'ASSOCIATE_MAILBOX',
	NO_MAILBOX_FOUNDED:'NO_MAILBOX_FOUNDED',
	DELETE_MAILBOX:'DELETE_MAILBOX',
	DETAIL_DIALOG3:'DETAIL_DIALOG3',

	// WORKSPACE TYPE
	WORKSPACE_TYPE_LIST:'WORKSPACE_TYPE_LIST',
	CHANGE_WORKSPACE_TYPE:'CHANGE_WORKSPACE_TYPE',
	NEW_WORKSPACE_TYPE:'NEW_WORKSPACE_TYPE',
	WORKSPACE_TYPE_NAME:'WORKSPACE_TYPE_NAME',

	/*  DEVELOP  */

	// STORYBOARD
	STORYBOARD_LIST:'STORYBOARD_LIST',
	STORYBOARD_DELETED:'STORYBOARD_DELETED',
	DELETE_STORYBOARD:'DELETE_STORYBOARD',
	DEVELOP_DIALOG:'DEVELOP_DIALOG',
	DRAFT:'DRAFT',
	NOBODY:'NOBODY',
	WORKFLOW_UPLOADED:'WORKFLOW_UPLOADED',
	STORYBOARD_SAVED:'STORYBOARD_SAVED',
	HIBERNATE_CLASS:'HIBERNATE_CLASS',
	WORKFLOW_STATUS:'WORKFLOW_STATUS',
	STORYBOARD_DIALOG:'STORYBOARD_DIALOG',
	UPLOAD_BPMN:'UPLOAD_BPMN',
	START_WORKFLOW:'START_WORKFLOW',
	ASSIGNEE:'ASSIGNEE',
	FINAL_USER:'FINAL_USER',
	END_WORKFLOW:'END_WORKFLOW',
	STORYBOARD_PERMISSION_DELETED:'STORYBOARD_PERMISSION_DELETED',
	TAKING_CHARGE_ALLOCATION:'TAKING_CHARGE_ALLOCATION',
	ASSOCIATED_TO:'ASSOCIATED_TO',
	UNINSTALLABLE:'UNINSTALLABLE',
	INSTANTIABLE:'INSTANTIABLE',
	ASSIGNABLE:'ASSIGNABLE',
	USABLE_BY_CONTACT:'USABLE_BY_CONTACT',
	INSTALLED:'INSTALLED',

	// STORYBOARD MIGRATION
	DEVELOP_ERROR:'DEVELOP_ERROR',
	CANCELLATION_DONE:'CANCELLATION_DONE',
	MIGRATION_DONE:'MIGRATION_DONE',
	STORYBOARD_TO_MIGRATE:'STORYBOARD_TO_MIGRATE',
	WORKFLOW_TO_MIGRATE_OLD:'WORKFLOW_TO_MIGRATE_OLD',
	WORKFLOW_TO_MIGRATE_NEW:'WORKFLOW_TO_MIGRATE_NEW',
	DO_MIGRATION:'DO_MIGRATION',

	/*  ADMINISTARTION  */

	// CONSOLE PYTHON
	RUN:'RUN',
	MANAGE_SYSTEM:'MANAGE_SYSTEM',
	CHECK_SYSTEM:'CHECK_SYSTEM',
	ADMINISTRATION_INFO:'ADMINISTRATION_INFO',
	VERIFY:'VERIFY',
	SYNC_PROVIDER:'SYNC_PROVIDER',
	ADMINISTRATION_INFO2:'ADMINISTRATION_INFO2',
	SYNC:'SYNC',
	RUN_WORKFLOW:'RUN_WORKFLOW',

	// SYSTEM

	/*  ASSETS  */

	// LIST

	// SALES ORDER

    SalesOrder:'Sales Order',
    SpareParts:'Spare Parts',
    Warranty:'Warranty',
    Tickets_general: 'General',
    Tickets_warranty: 'Warranty',
    BusinessPartner: 'Business Partner',
    BusinessPartner_dealer: 'Dealer',
    BusinessPartner_importer: 'Importer',
    BusinessPartner_customer: 'Customer',
	BusinessPartner_workshop: 'Workshop',
    BusinessPartner_new: 'Business Partner Creation',
	TICKET_WARRANTY: 'Warranty ticket',
    Configuration: 'Configuration',
    Users: 'Users',
	
	NOT_COMPLETED_:'NOT_COMPLETED_',
	ORDER:'ORDER',
	SALES_ORDER_DATE:'SALES_ORDER_DATE',
	EXPECTED_CLOSE_DATE:'EXPECTED_CLOSE_DATE',
	EXPECTED_COMPLETION_DATE:'EXPECTED_COMPLETION_DATE',
	DELAY:'DELAY',
	DAYS:'DAYS',
	ON_TIME:'ON_TIME',
	COMPLETION_DATE:'COMPLETION_DATE',
	PURCHASE_ORDER_DATE:'PURCHASE_ORDER_DATE',
	IMPORTED_ASSETS_FOR_ORDER:'IMPORTED_ASSETS_FOR_ORDER',
	SECONDARY_STATUS:'SECONDARY_STATUS',
	SHIPPING:'SHIPPING',
	PRODUCTION_COMPLETED:'PRODUCTION_COMPLETED',
	CLOSE_DATE:'CLOSE_DATE',
	EXPECTED:'EXPECTED',
	REQUESTED:'REQUESTED',
	CONFIGURED:'CONFIGURED',
	CONNECTIVITY:'CONNECTIVITY',

	// SPARE PARTS

	/*  MAINTENANCES  */

	// LIST

	/*  BUSINESS PARTNER  */

	// IMPORTER
	BUSINESS_PARTNER_CREATION:'BUSINESS_PARTNER_CREATION',
	CONTACTS:'CONTACTS',
	ADDRESS:'ADDRESS',
	CITY:'CITY',
	COUNTRY:'COUNTRY',
	SHOW_GEOLOCATION:'SHOW_GEOLOCATION',
	HIDE_GEOLOCATION:'HIDE_GEOLOCATION',
	WRITE_COMPLETE_ADDRESS:'WRITE_COMPLETE_ADDRESS',

	// DEALER

	// CUSTOMER

	/*  TICKETS  */
	SUPPORT:'SUPPORT',
	TICKET_CLOSED:'TICKET_CLOSED',
	TICKET_DELETED:'TICKET_DELETED',
	ADD_TO_CAMPAIGN:'ADD_TO_CAMPAIGN',
	ADD_TO_CAMPAIGN_:'ADD_TO_CAMPAIGN_',
	RUNNING_:'RUNNING_',
	COMPLETED:'COMPLETED',
	TICKET_INFO:'TICKET_INFO',
	AT:'AT',
	TICKET_INFO2:'TICKET_INFO2',
	TICKET_INFO3:'TICKET_INFO3',
	ON:'ON',
	AGENT:'AGENT',
	OPENED_BY:'OPENED_BY',
	OPEN_TODO:"OPEN_TODO",
	TERMINATE_TICKET:'TERMINATE_TICKET',
	TICKET_DIALOG:'TICKET_DIALOG',
	TERMINATE:'TERMINATE',
	DELETE_TICKET:'DELETE_DICKET',
	TICKET_DIALOG2:'TICKET_DIALOG2',
	ENTER_NOTE:'ENTER_NOTE',
	NOTE_SAVED:'NOTE_SAVED',
	DETAIL:'DETAIL',
	DETAILS:'DETAILS',
	DOCUMENTS:'DOCUMENTS',
	EXPORT:'EXPORT',
	SUMMARY:'SUMMARY',
	TASKS_LIST:'TASKS_LIST',
	TICKES_LIST:'TICKES_LIST',
	TICKET_NEW:'TICKET_NEW',
	TICKET_UPDATE:'TICKET_UPDATE',
	EXPORT_TICKET:'EXPORT_TICKET',
	WORKFLOW_RESET:'WORKFLOW_RESET',
	WORKFLOW_START:'WORKFLOW_START',
	TICKET_SAVED:'TICKET_SAVED',
	STOP_WORKFLOW:'STOP_WORKFLOW',
	START:'START',
	DEADLINE:'DEADLINE',
	ASSIGNED_PERSON:'ASSIGNED_PERSON',
	STOP:'STOP',
	TO_STOP:'TO_STOP',
	TO_START:'TO_START',
	TICKET_DIALOG3:'TICKET_DIALOG3',
	THE_WORKFLOW:'THE_WORKFLOW',
	ISSUED_BY:'ISSUED_BY',
	TICKET_OPEN:'TICKET_OPEN',
	BY:'BY',
	AGO:'AGO',
	LAST_CHANGE:'LAST_CHANGE',
	OPEN_PRACTICE:'OPEN_PRACTICE',
	CLOSING_NOTE:'CLOSING_NOTE',
	DETAIL_DIALOG4:'DETAIL_DIALOG',
	SELECT_ACTION:'SELECT_ACTION',
	OPERATION_COMPLETED:'OPERATION_COMPLETED',
	WITH_REASON:'WITH_REASON',
	COMMENT:'COMMENT',
	CHANGE_TICKET:'CHANGE_TICKET',
	ADD_TICKET:'ADD_TICKET',
	SUPPLY:'SUPPLY',
	FISCAL_CODE:'FISCAL_CODE',
	VAT_NUMBER:'VAT_NUMBER',
	REGISTRATION_NUMBER:'REGISTRATION_NUMBER',
	OHM_PARTNERSHIP_START:'OHM_PARTNERSHIP_START',
	TICKET_CLOSURE_DATE:'TICKET_CLOSURE_DATE',
	VIEW_DETAIL:'VIEW_DETAIL',
	COMPOSE:'COMPOSE',
	COPY_TICKET:'COPY_TICKET',
	TICKET_CHANGED:'TICKET_CHANGED',
	ADD_TODO:'ADD_TODO',
	CANCEL_TODO:'REMOVE_TODO',
	ACCEPTANCE_CARRIED_OUT:'ACCEPTANCE_CARRIED_OUT',
	VIN_NUMBER:'VIN_NUMBER',
	PLATE_NUMBER:'PLATE_NUMBER',
	ASSET_STATUS:'ASSET_STATUS',
	PRODUCTION_ORDER:'PRODUCTION_ORDER',
	DELIVERY_DATE:'DELIVERY_DATE',
	SEARCH_VIN:'SEARCH_VIN',
	SET_VIN:'SET_VIN',
	IMPORT_VEHICLE:'IMPORT_VEHICLE',
	IMPORTED:'IMPORTED',
	ERROR:'ERROR',
	SELECT_PRODUCTION_ORDER:'SELECT_PRODUCTION_ORDER',
	SELECT:'SELECT',
	WORKFLOW_TYPOLOGY:'WORKFLOW_TYPOLOGY',
	REQUEST_EXPORT:'REQUEST_EXPORT',
	EXPORT_START:'EXPORT_START',


	/*  OTHER  */
	SAVING:'SAVING',
	SAVE_E_IMPORT:'SAVE_E_IMPORT',
	IMPORT_BOM:'IMPORT_BOM',
	SELECT_MODEL:'SELCT_MODEL',
	VEHICLES_:'VEHICLES_',
	IMPORT_ITEMS:'IMPORT_ITEMS',
	IMPORT_PROGRESS:'IMPORT_PROGRESS',
	SEARCH_VEHICLE:'SEARCH_VEHICLE',
	SEARCH_PROGRESS:'SEARCH_PROGRESS',
	IN_WARRANTY:'IN_WARRANTY',
	WARRANTY_DATE:'WARRANTY_DATE',
	ASSEMBLING_:'ASSEMBLING_',
	CREATED_:'CREATED_',
	DELIVERED_:'DELIVERED_',
	READY_:'READY_',
	ASSEMBLING:'ASSEMBLING',
	CREATED:'CREATED',
	DELIVERED:'DELIVERED',
	READY:'READY',
	ASSIGNED_MY_DEPARTMENT:'ASSIGNED_MY_DEPARTMENT',
	STATUS_CLOSE:'STATUS_CLOSE',
	TODO_REMOVED:'TODO_REMOVED',
	EXPORT_TODO:'EXPORT_TODO',
	TODO_DIALOG:'TODO_DIALOG',
	TICKET_DETAIL:'TICKET_DETAIL',
	SOC_INFO:'SOC_INFO',
	SOH_INFO:'SOH_INFO',
	WORKING:'WORKING',
	IDLE:'IDLE',
	FLEET_ASSET_DIALOG:'FLEET_ASSET_DIALOG',
	BP_NAME_REQUIRED:'BP_NAME_REQUIRED',
	INVALID_MAIL_FORMAT:'INVALID_MAIL_FORMAT',
	INVALID_PHONE_FORMAT:'INVALID_PHONE_FORMAT',
	REG_NUM_VAT_NUM_REQUIRED:'REG_NUM_VAT_NUM_REQUIRED',
	IMPORTER_CUSTOMER_DEALER_REQUIRED:'IMPORTER_CUSTOMER_DEALER_REQUIRED',
	IMPORTER_PARENT_REQUIRED_DEALER:'IMPORTER_PARENT_REQUIRED_DEALER',
	IMPORTER_PARENT_REQUIRED_WORKSHOP:'IMPORTER_PARENT_REQUIRED_WORKSHOP',
	DEALER_PARENT_FOR_CUSTOMER:'DEALER_PARENT_FOR_CUSTOMER'
}

export const CONSTANT = {
	REQUIRED: 'Campo obbligatorio',
	NULLABLE: 'Campo obbligatorio',
	ACTION_ADD: 'Aggiungi',
	ACTION_CREATE: 'Crea',
	ACTION_SAVE: 'Salva',
	ACTION_DELETE: 'Cancella',
	REC_NEW: 'Nuovo',
	REC_UPDATE: 'Modifica',
	YEAR: 'Inserire anno corretto',
	EMAIL: 'Formato Email non corretto',
	NUMBER_POSITIVE: 'Inserire un valore positivo',
	NUMBER: 'Inserire un valore numerico',
	INTEGER: 'Inserire un valore intero',
	CURRENT_YEAR: new Date().getFullYear(),
	MOCK_DELAY: 1000,
	MOCK_ENABLED: false,
	STEP: 'STEP',
	INIT: 'INIT',
	DOCUMENT_REQUIRED: 'Inserisci il documento',
	PIC_REQUIRED: 'Inserisci la foto',
	FISCAL_CODE: 'Codice fiscale non corretto',
	VAT_CODE: 'Partita iva non corretta',
	WEB_APPS:'webApps',
	TICKET_COMPONENTS:'ticketComponent'
};

export const CONSTANT_SERVICE = {
	OFFERS: 'offers',
	TAGS: 'tags',
	WORKFLOW: 'workflow'
};

export const STATUS = {
	OK: 200,
	ERROR: 500
};

export const MESSAGE = {
	SUCCESS_DOC_SAVE: 'Documenti correttamente salvati',
	SUCCESS_SAVE: 'Dati correttamente salvati',
	SUCCESS_EDIT: 'Dati correttamente aggiornati',
	ERROR: 'Ops! Qualcosa è andato storto'
};

export const FILES = {
	APE: 'ape-pre-intervento',
	CI: 'documento-identita',
	CF: 'codice-fiscale',
	EDIFICIO_EXT: 'edificio-esterno',
	PORTE: 'porte-interne',
	FINESTRE: 'finestre',
	SCHEDA_CALDAIA: 'scheda-tecnica-caldaia',
	CALDAIA: 'caldaia',
	MURI: 'muri',
	ELEMENTI_RADIANTI: 'elementi-radianti',
	ELEMENTO_AUTORISCALDANTE: 'elemento-autoriscaldante',
	BOLLETTA_E: 'bolletta-energia-elettrica',
	BOLLETTA_G: 'bolletta-combustibile',
	VISURA: 'visura-catastale',
	PLANIMETRIA: 'planimetria',
	CONSENSO: 'consenso-gdpr',
	AGIBILITA: 'agibilita',
	ABUSI: 'abusi',
	VINCOLI: 'vincoli',
	LAVORI_EXTRA: 'lavori-extra',
	DISEGNI_PROGETTO: 'disegni-progetto',
	PROGETTAZIONE: 'progettazione',
	VARIE: 'varie',
	PROGETTO_PRELIMINARE: 'progetto-preliminare',
	PROGETTO_ESECUTIVO: 'progetto-esecutivo',
	ALTRI_DOCUMENTI: 'altri-documenti',
	TAVOLE: 'tavole',
	RELAZIONE: 'relazione',
	COMPUTO_METRICO: 'computo-metrico',
	STRATIGAFIA_CALCOLI: 'stratigrafia-e-calcoli',
	ANTI_MAFIA: 'attestato-anti-mafia',
	ATTESTAZIONE_SOA: 'attestazione-soa',
	ASSENZA_CARICHI_PENALI: 'dichiarazione-assenza-carichi-penali',
	ASSENZA_CARICHI_TRIBUTARI: 'dichiarazione-assenza-carichi-tributari',
	DURC: 'durc',
	GDPR: 'gdpr',
	ATTO_PROPRIETA: 'atto-propr',
	LAVORI_RISTRUTTURAZIONE: 'lavori-ristrutturazione',
	DOCUMENTO_FIRMATO_CONTRATTO: 'doc-contr-sign',
	DOCUMENTO_FIRMATO_PRE_CONTRATTO: 'doc-pre-contr-sign',
	ASSEVERAZIONI: 'asseverazioni',
	TABELLE_MILLESIMALE: 'tab-millesimale-prop',
	REGOLAMENTO_CONDOMINIO: 'regolamento-condominio',
	ATTO_COSTITUZIONE_CONDOMINIO: 'atto-costituzione-condominio',
	REGISTRO_ANAGRAFICA: 'registro-anagrafica',
	PRATICHE_EDILIZIE: 'pratiche',
	CI_COMM: 'documento-identita-committente',
	CF_COMM: 'codice-fiscale-committente',
	CONSENSO_PROPRIETARIO: 'consenso-propr',
};

export const TABS = {
	WORKFLOW: 'workflow',
	INDEX: 'index',
	OWNER: 'owner',
	CONDOMINIUM: 'condominium',
	HOUSING: 'housing',
	GENERAL: 'general',
	SPECIFIC: 'specific',
	FIXTURES: 'fixtures',
	INSTALLATIONS: 'installations',
	CONTRACTS: 'contracts',
	OTHER: 'other',
	ATTACHMENTS: 'attachments',
	BACKOFFICE: 'backoffice',
	DETAILS:'details'
};

export const CONSTANT_KEYS = {
	BE_APP_NAME: 'appName',
	BE_APP_ICON: 'appIcon',
	BE_GMAPS_KEY: 'appGoogleMapsApiKey',
	BE_AGENCY_STYLE: 'clientAgencyStyle',
	BE_CONTRACT_MULTILINE: 'appContractMultiline',
	BE_CONTRACT_SUPPLY_INVOICE: 'appContractSupplyInvoice',
	BE_CONTRACT_AGENCY_MODE: 'appContractAgencyMode',
	BE_USERNAME_GENERATION: 'usernameGeneration',
	BE_RELATIONS_CUSTOMER_TYPE_TYPOLOGY_USE: 'appRelationsCustomerTypeTypologyUse',
	BE_ECOBONUS_GDPR: 'appEcobonusGdpr',
	ASSIGN_WORKSPACE_AGENTS: 'assignWorkspaceForAgents',
	CONTRACT: 'contract',
	PRECONTRACT: 'precontract',
	BPARTNER: 'bpartner',
	APP_CODE: 'appCode',
	PRIVACY_URL: 'appPrivacyUrl',
	CUSTOMER_TRANSLATION:'customerTranslation',
	URL_GOOGLE_PLAY_APP:'urlGoogleApp',
	URL_APPLE_APP:'urlAppleApp',
	TIMER_REFRESH_TOKEN:'timerTokenRefresh'
};

export const TYPES_DEVICE_ALL = [];

export const TYPES_ELECTRIC_DEVICE = [
    {
        label:  TRANSLATE.VIN_NUMBER,
        name: 'vin',
        type: 'text'
    },
	{
        label: TRANSLATE.PLATE_NUMBER,
        name: 'plate',
        type: 'text'
    },
	{
        label: TRANSLATE.STATUS,
        name: 'status',
        type: 'text'
    },
	{
        label: TRANSLATE.IMPORTER,
        name: 'importer',
        type: 'text'
    },
	{
        label: TRANSLATE.DEALER,
        name: 'dealer',
        type: 'text'
    },
	{
        label: TRANSLATE.CUSTOMER,
        name: 'customer',
        type: 'text'
    }
];

export const TYPES_MAINTENANCE_ACTIVITIES = [
    {
        label:  TRANSLATE.VIN_NUMBER,
        name: 'vin',
        type: 'text'
    },
	{
        label: TRANSLATE.PLATE_NUMBER,
        name: 'plate',
        type: 'text'
    },
	{
        label: TRANSLATE.MAINTENANCE_NUMBER,
        name: 'maintenanceNumber',
        type: 'text'
    }
];

export const TYPES_USERS_SEARCH = [
    { 
        label: 'Username',
        name: 'username',
        type: 'text'
    }
    
];

export const TYPES_SALES_ORDER = [
    {
        label: 'Order Id',
        name: 'orderId',
        type: 'number'
    }
    
];


export const TYPES_SPARE_PARTS = [
    {
        label: 'Part Number',
        name: 'partNumber',
        type: 'text'
    },
	{
        label: 'Vin',
        name: 'vin',
        type: 'text'
    }
    
];


export const TYPES_ELECTRIC_TRIP = [

	{
        label: TRANSLATE.DEVICE,
        name: 'asset',
        type: 'text'
    },
	{
        label: TRANSLATE.FROM_TRIP,
        name: 'from',
        type: 'date'
    },
	{
        label: TRANSLATE.TO_TRIP,
        name: 'to',
        type: 'date'
    },
	{
        label: TRANSLATE.STATUS,
        name: 'status',
        type: 'enum',
		values: [
			{
				code: 'START',
				label: TRANSLATE.START
			},
			{
				code: 'END',
				label: TRANSLATE.END
			},
			{
				code: 'RUN',
				label: TRANSLATE.RUN
			},
		]
    },

];

export const TYPES_ELECTRIC_TRIP_DETAIL = [

{
label: TRANSLATE.FROM_TRIP,
name: 'from',
type: 'date'
},
{
label: TRANSLATE.TO_TRIP,
name: 'to',
type: 'date'
}

];

export const TYPES_ELECTRIC_RECHARGES = [

	{
		label: TRANSLATE.DEVICE,
		name: 'asset',
		type: 'text'
	},
	{
		label: TRANSLATE.FROM_CHARGE,
		name: 'from',
		type: 'date'
	},
	{
		label: TRANSLATE.TO_CHARGE,
		name: 'to',
		type: 'date'
	}

];


export const TYPES_ELECTRIC_RECHARGES_DETAIL = [

{
label: TRANSLATE.FROM_CHARGE,
name: 'from',
type: 'date'
},
{
label: TRANSLATE.TO_CHARGE,
name: 'to',
type: 'date'
}

];


export const TYPES_ELECTRIC_DIAGNOSTICS = [
 
	{
        label: TRANSLATE.DEVICE,
        name: 'asset',
        type: 'text'
    },{
        label: TRANSLATE.DESCRIPTION,
        name: 'name',
        type: 'text'
    },
	{
        label: TRANSLATE.GROUP,
        name: 'group',
        type: 'text'
    },{
        label: TRANSLATE.DATE,
        name: 'date',
        type: 'date'
    }
];


export const TYPES_ELECTRIC_DIAGNOSTICS_DETAIL = [
{
label: TRANSLATE.DATE,
name: 'date',
type: 'date'
}

];


export const TYPES_ALARM = [
 
	{
        label: TRANSLATE.DEVICE,
        name: 'asset',
        type: 'text'
    },{
        label: TRANSLATE.DESCRIPTION,
        name: 'description',
        type: 'text'
    },
	{
        label: TRANSLATE.STATUS,
        name: 'status',
        type: 'enum',
		values: [
			{
				code: 'CLEARED',
				label: TRANSLATE.CLOSE
			},
			{
				code: 'ACTIVE',
				label: TRANSLATE.ACTIVE
			}
		]
    },{
        label: TRANSLATE.SEVERITY,
        name: 'severity',
        type: 'enum',
		values: [
			{
				code: 'CRITICAL',
				label: TRANSLATE.CRITICAL
			},
			{
				code: 'LOW',
				label: TRANSLATE.LOW
			}
		]
    },{
        label: TRANSLATE.START_DATE_,
        name: 'startDate',
        type: 'date'
    },{
        label: TRANSLATE.END_DATE_,
        name: 'endDate',
        type: 'date'
    }
];

export const TYPES_ELECTRIC_MAINTENANCE = [
    {
        label: 'Number',
        name: 'interventionNumber',
        type: 'text',
    }
];

export const TYPES_ELECTRIC_MAINTENANCE_DETAIL = [
    {
        label: 'Imei',
        name: 'imei',
        type: 'text'
    },
	{
        label: TRANSLATE.SERIAL_NUMBER,
        name: 'serialNumber',
        type: 'text'
    },
	{
        label: 'Service Date',
        name: 'serviceDate',
        type: 'date'
    }
];


export const TYPES_TICKET_AGENCY_STYLE = [
	{
		label: TRANSLATE.STATUS,
		name: 'status',
		type: 'enum',
		values: [
			{
				code: 'OPEN',
				label: TRANSLATE.OPEN_
			},
			{
				code: 'CLOSE',
				label: TRANSLATE.CLOSE_
			},
			{
				code: 'DRAFT',
				label: TRANSLATE.DRAFT_
			}
		]
	},
	{
		label: TRANSLATE.ASSIGNMENT,
		name: 'assign',
		type: 'enum',
		values: [
			{
				label: TRANSLATE.ASSIGNED_TO_ME,
				code: 'me'
			},
			{
				label: TRANSLATE.ALL_ASSIGNMENTS,
				code: 'all'
			}
		]
	},
	{
		label: TRANSLATE.LAST_NAME_ASSIGNEE,
		name: 'lastNameAss',
		type: 'text'
	},
	{
		label: TRANSLATE.NAME_ASSIGNEE,
		name: 'firstNameAss',
		type: 'text'
	},
	{
		label: TRANSLATE.LAST_NAME_REPORTER,
		name: 'lastNameRep',
		type: 'text'
	},
	{
		label: TRANSLATE.NAME_REPORTER,
		name: 'firstNameRep',
		type: 'text'
	},
	{
		label: TRANSLATE.DESCRIPTION,
		name: 'description',
		type: 'text'
	},
	{
		label: TRANSLATE.WORKFLOW,
		name: 'storyboard.name',
		type: 'text'
	},
	{
		label: TRANSLATE.ISSUED_DEPARTMENT,
		name: 'workspace.name',
		type: 'text'
	},
	{
		label: TRANSLATE.ASSIGNED_DEPARTMENT,
		name: 'assignWorkspaceName',
		type: 'text'
	},
	{
		label: TRANSLATE.EXPIRED,
		name: 'expired',
		type: 'enum',
		values: [
			{
				label: TRANSLATE.YES_,
				code: true
			}
		]
	},
	{
		label: TRANSLATE.CREATION_DATE,
		name: 'created',
		type: 'date'
	},
	{
		label: TRANSLATE.EXPIRY_DATE,
		name: 'expireDate',
		type: 'date'
	},
	{
		label: TRANSLATE.MODIFICATION_DATE,
		name: 'modified',
		type: 'date'
	},
	{
		label: TRANSLATE.TYPOLOGY,
		name: 'typeName',
		type: 'text'
	},
	{
		label: TRANSLATE.CUSTOMER,
		name: 'name',
		type: 'text'
	},
	{
		label: TRANSLATE.TICKET_NUMBER,
		name: 'id',
		type: 'text'
	}
];

export const TYPES_TICKET = [
	{
		label: TRANSLATE.STATUS,
		name: 'status',
		type: 'enum',
		values: [
			{
				code: 'OPEN',
				label: TRANSLATE.OPEN_
			},
			{
				code: 'CLOSE',
				label: TRANSLATE.CLOSE_
			},
			{
				code: 'DRAFT',
				label: TRANSLATE.DRAFT_
			}
		]
	},
	{
		label: TRANSLATE.ASSIGNMENT,
		name: 'assign',
		type: 'enum',
		values: [
			{
				label: TRANSLATE.ASSIGNED_TO_ME,
				code: 'me'
			},
			{
				label: TRANSLATE.ALL_ASSIGNMENTS,
				code: 'all'
			}
		]
	},
	{
		label: TRANSLATE.PROCESSING_STATUS,
		name: 'workStatus',
		type: 'enum',
		values: [
			{
				label: TRANSLATE.TO_WORK,
				code: 'toWork'
			},
			{
				label: TRANSLATE.TAKEN_IN_CHARGE,
				code: 'inProgress'
			},
			{
				label: TRANSLATE.ALL,
				code: 'all'
			},
		]
	},
	{
		label: TRANSLATE.LAST_NAME_ASSIGNEE,
		name: 'lastNameAss',
		type: 'text'
	},
	{
		label: TRANSLATE.NAME_ASSIGNEE,
		name: 'firstNameAss',
		type: 'text'
	},
	{
		label: TRANSLATE.LAST_NAME_REPORTER,
		name: 'lastNameRep',
		type: 'text'
	},
	{
		label: TRANSLATE.NAME_REPORTER,
		name: 'firstNameRep',
		type: 'text'
	},
	{
		label: TRANSLATE.DESCRIPTION,
		name: 'description',
		type: 'text'
	},
	{
		label: TRANSLATE.WORKFLOW,
		name: 'storyboard.name',
		type: 'text'
	},
	{
		label: TRANSLATE.ISSUED_DEPARTMENT,
		name: 'workspace.name',
		type: 'text'
	},
	{
		label: TRANSLATE.ASSIGNED_DEPARTMENT,
		name: 'assignWorkspaceName',
		type: 'text'
	},
	{
		label: TRANSLATE.EXPIRED,
		name: 'expired',
		type: 'enum',
		values: [
			{
				label: TRANSLATE.YES_,
				code: true
			}
		]
	},
	{
		label: TRANSLATE.CREATION_DATE,
		name: 'created',
		type: 'date'
	},
	{
		label: TRANSLATE.EXPIRY_DATE,
		name: 'expireDate',
		type: 'date'
	},
	{
		label: TRANSLATE.MODIFICATION_DATE,
		name: 'modified',
		type: 'date'
	},
	{
		label: TRANSLATE.TYPOLOGY,
		name: 'typeName',
		type: 'text'
	},
	{
		label: TRANSLATE.CUSTOMER,
		name: 'name',
		type: 'text'
	},
	{
		label: TRANSLATE.TICKET_NUMBER,
		name: 'id',
		type: 'text'
	}
];


export const TYPES_TASK_AGENCY_STYLE = [
	{
		label: TRANSLATE.TITLE,
		name: 'subject',
		type: 'text'
	},
	{
		label: TRANSLATE.ASSIGNED_DEPARTMENT,
		name: 'assignWorkspaceName',
		type: 'text'
	},
	{
		label: TRANSLATE.ASSIGNMENT,
		name: 'assign',
		type: 'enum',
		values: [
			{
				label: TRANSLATE.ASSIGNED_TO_ME,
				code: 'me'
			},
			{
				label: TRANSLATE.ALL_ASSIGNMENTS,
				code: 'all'
			}
		]
	},
	{
		label: TRANSLATE.WORKFLOW,
		name: 'storyboard.name',
		type: 'text'
	},
	{
		label: TRANSLATE.TICKET_NUMBER,
		name: 'workflowId',
		type: 'text'
	}
];

export const TYPES_TODO = [
	{
		label: TRANSLATE.STATUS,
		name: 'status',
		type: 'enum',
		values: [
			{
				code: 'work',
				label: TRANSLATE.OPEN_
			},
			{
				code: 'end',
				label: TRANSLATE.COMPLETED_
			}
		]
	},
	{
		label: TRANSLATE.TITLE,
		name: 'title',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: TRANSLATE.LAST_NAME_ASSIGNEE,
		name: 'lastNameAss',
		type: 'text'
	},
	{
		label: TRANSLATE.NAME_ASSIGNEE,
		name: 'firstNameAss',
		type: 'text'
	},
	{
		label: TRANSLATE.LAST_NAME_REPORTER,
		name: 'lastNameRep',
		type: 'text'
	},
	{
		label: TRANSLATE.NAME_REPORTER,
		name: 'firstNameRep',
		type: 'text'
	},
	{
		label: TRANSLATE.DESCRIPTION,
		name: 'description',
		type: 'text'
	},
	{
		label: TRANSLATE.WORKFLOW,
		name: 'storyboard.name',
		type: 'text'
	},
	{
		label: TRANSLATE.DEPARTMENT_NAME,
		name: 'workspace.name',
		type: 'text'
	},
	{
		label: TRANSLATE.CREATION_DATE,
		name: 'created',
		type: 'date'
	},
	{
		label: TRANSLATE.MODIFICATION_DATE,
		name: 'modified',
		type: 'date'
	},
	{
		label: TRANSLATE.CUSTOMER,
		name: 'customer',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	}
];

export const TYPES_TASK = [
	{
		label: TRANSLATE.PROCESSING_STATUS,
		name: 'workStatus',
		type: 'enum',
		values: [
			{
				label: TRANSLATE.TO_WORK,
				code: 'toWork'
			},
			{
				label: TRANSLATE.TAKEN_IN_CHARGE,
				code: 'inProgress'
			},
			{
				label: TRANSLATE.ALL,
				code: 'all'
			},
		]
	},
	{
		label: TRANSLATE.TITLE,
		name: 'subject',
		type: 'text'
	},
	{
		label: TRANSLATE.ASSIGNED_DEPARTMENT,
		name: 'assignWorkspaceName',
		type: 'text'
	},
	{
		label: TRANSLATE.ASSIGNMENT,
		name: 'assign',
		type: 'enum',
		values: [
			{
				label: TRANSLATE.ASSIGNED_TO_ME,
				code: 'me'
			},
			{
				label: TRANSLATE.ALL_ASSIGNMENTS,
				code: 'all'
			}
		]
	},
	{
		label: TRANSLATE.WORKFLOW,
		name: 'storyboard.name',
		type: 'text'
	},
	{
		label: TRANSLATE.TICKET_NUMBER,
		name: 'workflowId',
		type: 'text'
	}
];

export const ALARMS = [
	{
		label: 'Severity',
		name: 'severity',
		type: 'enum',
		values: [
			{
				label: 'MAJOR',
				code: '0'
			},
			{
				label: 'LOW',
				code: '1'
			}
		]
	}
];

export const TYPES_FUNCTIONDEFINITION = [
	{
		label: TRANSLATE.DESCRIPTION,
		name: 'description',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: TRANSLATE.KEY,
		name: 'key',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	}
];

export const TYPES_GROUPS = [
	{
		label: TRANSLATE.NAME,
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	}
];

export const TYPES_MAILBOX = [
	{
		label: TRANSLATE.NAME,
		name: 'spotlight',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: TRANSLATE.STATUS,
		name: 'enabled',
		type: 'enum',
		values: [
			{
				label: TRANSLATE.ACTIVE_,
				code: true
			},
			{
				label: TRANSLATE.INACTIVE_,
				code: false
			}
		]
	}
];

export const TYPES_STORYBOARD = [
	{
		label: TRANSLATE.NAME,
		name: 'spotlight',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: TRANSLATE.DESCRIPTION,
		name: 'description',
		type: 'text'
	},
	{
		label: TRANSLATE.STATUS,
		name: 'status',
		type: 'enum',
		values: [
			{
				label: TRANSLATE.ACTIVE_,
				code: 'ACTIVE'
			},
			{
				label: TRANSLATE.INACTIVE_,
				code: 'INACTIVE'
			},
			{
				label: TRANSLATE.DRAFT_,
				code: 'DRAFT'
			}
		]
	}
];

export const TYPES_REPORT_DEFINITIONS = [
	{
		label: TRANSLATE.CODE,
		name: 'code',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: TRANSLATE.NAME,
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Data inserimento',
		name: 'created',
		type: 'date'
	},
	{
		label: 'Data modifica',
		name: 'modified',
		type: 'date'
	},
	{
		label: 'Creato da',
		name: 'createdBy',
		type: 'text'
	},
	{
		label: 'Modificato da',
		name: 'modifiedBy',
		type: 'text'
	}
];

export const TYPES_REPORT_PARAMETERS = [
	{
		label: TRANSLATE.CODE,
		name: 'code',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: TRANSLATE.NAME,
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Definizione',
		name: 'definition',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Data inserimento',
		name: 'created',
		type: 'date'
	},
	{
		label: 'Data modifica',
		name: 'modified',
		type: 'date'
	},
	{
		label: 'Creato da',
		name: 'createdBy',
		type: 'text'
	},
	{
		label: 'Modificato da',
		name: 'modifiedBy',
		type: 'text'
	}
];

export const TYPES_TENANT = [
	{
		label: TRANSLATE.NAME,
		name: 'spotlight',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: TRANSLATE.DESCRIPTION,
		name: 'description',
		type: 'text'
	},
	{
		label: TRANSLATE.STATUS,
		name: 'enabled',
		type: 'enum',
		values: [
			{
				label: TRANSLATE.ACTIVE_,
				code: true
			},
			{
				label: TRANSLATE.INACTIVE_,
				code: false
			}
		]
	}
];

export const TYPES_WORKSPACE = [
	{
		label: TRANSLATE.NAME,
		name: 'spotlight',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: TRANSLATE.DESCRIPTION,
		name: 'description',
		type: 'text'
	},
	{
		label: TRANSLATE.STATUS,
		name: 'enabled',
		type: 'enum',
		values: [
			{
				label: TRANSLATE.ACTIVE_,
				code: true
			},
			{
				label: TRANSLATE.INACTIVE_,
				code: false
			}
		]
	}
];

export const TYPES_TAG = [
	{
		label: TRANSLATE.NAME,
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	}
];

export const TYPES_TEMPLATE = [
	{
		label: TRANSLATE.NAME,
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	}
];

export const TYPES_USER = [
	{
		label: TRANSLATE.NAME,
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Email',
		name: 'mail',
		type: 'text'
	},
	{
		label: 'UserId',
		name: 'id',
		type: 'text'
	},
	{
		label: TRANSLATE.GROUP,
		name: 'group',
		type: 'text'
	},
	{
		label: TRANSLATE.ROLE,
		name: 'auth',
		type: 'enum',
		values: [
			{ code: 'ROLE_USER', label: TRANSLATE.USER },
			{ code: 'ROLE_ADMINISTRATOR', label: TRANSLATE.SYSTEM_ADMINISTRATOR },
			{ code: 'ROLE_SUPERVISOR', label: TRANSLATE.SYSTEM_SUPERVISOR },
			{ code: 'ROLE_AGENCY', label: TRANSLATE.AGENCY_ROLE },
			{ code: 'ROLE_AGENT', label: TRANSLATE.AGENT_ROLE },
			{ code: 'ROLE_BACKOFFICE', label: TRANSLATE.BACKOFFICE_ROLE }
		]
	},
	{
		label: TRANSLATE.STATUS,
		name: 'enabled',
		type: 'enum',
		values: [
			{
				label: TRANSLATE.ACTIVE_,
				code: true
			},
			{
				label: TRANSLATE.INACTIVE_,
				code: false
			}
		]
	}
];

export const TYPES_BUSINESS_PARTNER_SEARCH = [
    {
        label: TRANSLATE.NAME,
        name: 'name',
        type: 'text'
    }
    
];

export const TYPES_MEASURE_GROUP = [
	{
		label: TRANSLATE.DESCRIPTION,
		name: 'description',
		type: 'text',
	},
	{
		label: TRANSLATE.ENABLED,
		name: 'enabled',
		type: 'enum',
		values: [
			{
				label: TRANSLATE.TRUE,
				code: true
			},
			{
				label: TRANSLATE.FALSE,
				code: false
			}
		]
	}
];

export const TYPES_MEASURE = [
	{
		label: TRANSLATE.CODE,
		name: 'code',
		type: 'text',
	},
	{
		label: TRANSLATE.DESCRIPTION,
		name: 'description',
		type: 'text',
	},
	
	{
		label: TRANSLATE.TYPE,
		name: 'type',
		type: 'enum',
		values: [
			{
				label: TRANSLATE.METRICS,
				code: "M"
			},
			{
				label: TRANSLATE.PROPERTIES,
				code: "P"
			}
		]
	}
];

export const TYPES_ASSOCIATION = [
	{
		label: TRANSLATE.GROUP,
		name: 'group',
		type: 'text',
	},
	{
		label: TRANSLATE.MEASURES,
		name: 'measure',
		type: 'text',
	},
	{
		label: TRANSLATE.ASSET_TYPE,
		name: 'assetType',
		type: 'text',
	},
	{
		label: TRANSLATE.ENABLED,
		name: 'enabled',
		type: 'enum',
		values: [
			{
				label: TRANSLATE.TRUE,
				code: true
			},
			{
				label: TRANSLATE.FALSE,
				code: false
			}
		]
	}
];

export const TYPES_FLEET = [
	{
		label: TRANSLATE.DESCRIPTION,
		name: 'description',
		type: 'text'
	},
	{
		label: TRANSLATE.CODE,
		name: 'code',
		type: 'text'
	}
];

export const TYPES_ASSET_TYPE = [
	{
		label: TRANSLATE.DESCRIPTION,
		name: 'description',
		type: 'text'
	},
	{
		label: TRANSLATE.DEVICE,
		name: 'asset',
		type: 'enum',
		type: 'text'
	}
];

export const TYPES_ASSET = [
	{
		label: TRANSLATE.DESCRIPTION,
		name: 'description',
		type: 'text'
	}
];

export const TYPES_ASSET_TYPE_DETAIL = [
	{
		label: TRANSLATE.DESCRIPTION,
		name: 'description',
		type: 'text'
	}
];

export const TYPES_USER_ASSOCIATION = [
	{
		label: TRANSLATE.DEVICE,
		name: 'idAsset',
		type: 'text'
	},
	{
        label: TRANSLATE.USER,
        name: 'idUser',
        type: 'text'
    }
];

export const TYPES_CONTACT = [
	{
		label: 'Nome',
		name: 'firstName',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Telefono',
		name: 'phoneNo1',
		type: 'text'
	},
	{
		label: 'Cellulare',
		name: 'cellNo1',
		type: 'text'
	},
	{
		label: 'Email',
		name: 'email',
		type: 'text'
	}
];
export const TYPES_MAILS = [
	{
		label: 'Oggetto',
		name: 'subject',
		type: 'text'
	},
	{
		label: 'Mittente',
		name: 'from',
		type: 'text'
	},
	{
		label: 'Destinatario',
		name: 'to',
		type: 'text'
	}
];
export const TYPES_TEMPLATE_MESSAGE = [
	{
		label: 'Titolo',
		name: 'title',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Messaggio',
		name: 'body',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Mailbox',
		name: 'mailbox.name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	}
];
export const THEMES = {
	// DEFAULT
	POST: {
		name: 'POST',
		label: 'POST',
	  },
	LIGHT_DEFAULT: {
	  name: 'LIGHT_DEFAULT',
	  label: TRANSLATE.LIGHT_DEFAULT_,
	},
	DARK: {
	  name: 'DARK',
	  label: TRANSLATE.DARK_,
	}
	// CUSTOM
  };
  
  export const LANGUAGES = {
	
	'en-EN': {
	  name: 'en-EN',
	  label: TRANSLATE.ENGLISH_,
	},
	'it-IT': {
	  name: 'it-IT',
	  label: TRANSLATE.ITALIAN_,
	}
  
  }