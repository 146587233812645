/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import {
	Avatar,
	Box,
	Chip,
	Divider,
	Drawer,
	Hidden,
	Link,
	List,
	ListSubheader,
	makeStyles,
	Typography
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons';
import {
	AlertCircle as AlertCircleIcon,
	BarChart as BarChartIcon,
	Briefcase as BriefcaseIcon,
	Calendar as CalendarIcon,
	Cast as CastIcon,
	Clock as ClockIcon,
	DollarSign as DollarSignIcon,
	Edit as EditIcon,
	Folder as FolderIcon,
	Layout as LayoutIcon,
	Lock as LockIcon,
	Mail as MailIcon,
	MessageCircle as MessageCircleIcon,
	PieChart as PieChartIcon,
	Settings as SettingsIcon,
	Share2 as ShareIcon,
	Shield as ShieldIcon,
	ShoppingCart as ShoppingCartIcon,
	Terminal as TerminalIcon,
	User as UserIcon,
	UserPlus as UserPlusIcon,
	Users as UsersIcon
} from 'react-feather';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'src/components/Logo';
import userInfo from 'src/services/userInfo';
import NavItem from './NavItem';
import { useTranslation } from "react-i18next";
import { TRANSLATE } from 'src/submodules/commons-misc/constant';

function renderNavItems({ items, ...rest }) {
	return (
		<List disablePadding>
			{items.reduce(
				(acc, item) => reduceChildRoutes({ acc, item, ...rest }),
				[]
			)}
		</List>
	);
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
	const key = item.title + depth;	

	if (item.items) {
		
		let total = 0;
		for (let subitem of item.items) {
			if (userInfo.can(subitem.acl)) {
				total++;
			}
		}

		if (total > 0) {
			const open = true;

			if (userInfo.can(item.acl)) {
				acc.push(
					<NavItem
						depth={depth}
						icon={item.icon}
						key={key}
						info={item.info}
						open={Boolean(open)}
						title={item.title}
					>
						{renderNavItems({
							depth: depth + 1,
							pathname,
							items: item.items
						})}
					</NavItem>
				);
			}
		}
	} else {
		if (userInfo.can(item.acl)) {
			acc.push(
				<NavItem
					depth={depth}
					href={item.href}
					icon={item.icon}
					key={key}
					info={item.info}
					title={item.title}
				/>
			);
		}
	}

	return acc;
}

const useStyles = makeStyles(theme => ({
	mobileDrawer: {
		width: 256
	},
	desktopDrawer: {
		width: 256,
		top: 64,
		height: 'calc(100% - 64px)'
	},
	avatar: {
		cursor: 'pointer',
		width: 64,
		height: 64
	},
	header: {
		background: theme.palette.primary.main
	},
	headerTitle: {
		color: theme.palette.primary.contrastText
	},
	logo: {
		maxWidth: 200,
		maxHeight: 60
	}
}));

function NavBar({ openMobile, onMobileClose }) {
	const classes = useStyles();
	const location = useLocation();
	const { user } = useSelector(state => state.account);
	const { t } = useTranslation();
	
	const navConfig = [
		{
			items: [
				{
					title: t(TRANSLATE.ACCESSES),
					icon: BriefcaseIcon,
					items: [
						{
							title: t(TRANSLATE.USERS),
							acl: 'commons.menu.usermanagement',
							href: '/app/system/users',
							icon: BriefcaseIcon
						},
						{
							title: t(TRANSLATE.PERMISSION),
							acl: 'commons.menu.usermanagement',
							href: '/app/system/groups',
							icon: BriefcaseIcon
						},
						{
							title: t(TRANSLATE.CONFIGURATIONS),
							acl: 'commons.menu.usermanagement',
							href: '/app/system/configurations',
							icon: BriefcaseIcon
						}
					]
				},
				{
					title: t(TRANSLATE.CONFIGURATION),
					icon: BriefcaseIcon,
					items: [
						{
							title: t(TRANSLATE.TENANT),
							acl: 'commons.menu.tenant',
							href: '/app/system/tenants',
							icon: BriefcaseIcon
						}
						,
						{
							title: t(TRANSLATE.WORKFLOW_TYPE),
							acl: 'commons.menu.ticket',
							href: '/app/system/ticketType',
							icon: BriefcaseIcon
						},
						// {
						// 	title: 'Parametri Report',
						// 	acl: 'commons.menu.reportdefinition',
						// 	href: '/app/system/reportdefinition',
						// 	icon: BriefcaseIcon
						// }
					]
				},
				// {
				// 	title: 'Documenti',
				// 	icon: BriefcaseIcon,
				// 	items: [
				// 		{
				// 			title: 'Tag',
				// 			acl: 'commons.menu.tagDocuments',
				// 			href: '/app/system/tags',
				// 			icon: BriefcaseIcon
				// 		},
				// 		{
				// 			title: 'Template',
				// 			acl: 'commons.menu.templateDocuments',
				// 			href: '/app/system/templates',
				// 			icon: BriefcaseIcon
				// 		}
				// 	]
				// },
				// {
				// 	title: 'Mailbox',
				// 	icon: BriefcaseIcon,
				// 	items: [
				// 		{
				// 			title: 'Mailbox',
				// 			acl: 'commons.menu.mailbox',
				// 			href: '/app/system/mailbox',
				// 			icon: BriefcaseIcon
				// 		},
				// 		{
				// 			title: 'Template',
				// 			acl: 'commons.menu.templateMessages',
				// 			href: '/app/webmail/templates',
				// 			icon: BriefcaseIcon
				// 		}
				// 	]
				// },
				{
					title: t(TRANSLATE.WORKSPACE),
					icon: BriefcaseIcon,
					items: [
						{
							title: t(TRANSLATE.WORKSPACE),
							acl: 'commons.menu.workspace',
							href: '/app/system/workspaces',
							icon: BriefcaseIcon
						},
						{
							title: t(TRANSLATE.WORKSPACE_TYPE),
							acl: 'commons.menu.workspace',
							href: '/app/system/workspacesType',
							icon: BriefcaseIcon
						}
					]
				},
				{
					title: t(TRANSLATE.DEVELOP),
					icon: BriefcaseIcon,
					items: [
						{
							title: t(TRANSLATE.STORYBOARD),
							acl: 'commons.menu.storyboard',
							href: '/app/system/storyboards',
							icon: BriefcaseIcon
						},
						{
							title: t(TRANSLATE.STORYBOARD_MIGRATION),
							acl: 'commons.menu.storyboard',
							href: '/app/system/migrations',
							icon: BriefcaseIcon
						}
					]
				},
					{
						title: t(TRANSLATE.ADMINISTRATION),
						icon: BriefcaseIcon,
						items: [
							{
								title: t(TRANSLATE.CONSOLE_PYTHON),
								acl: 'commons.menu.console',
								href: '/app/system/console',
								icon: TerminalIcon
							},
							{
								title: t(TRANSLATE.SYSTEM),
								acl: 'commons.menu.system',
								href: '/app/system/system',
								icon: SettingsIcon
							}
						]
					}
			]
		}
	];

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
		// eslint-disable-next-line
	}, [location.pathname]);

	const content = (
		<Box height="100%" display="flex" flexDirection="column">
			<PerfectScrollbar options={{ suppressScrollX: true }}>
				<Hidden lgUp>
					<Box className={classes.header} pt={2}>
						<Box
							p={2}
							display="flex"
							justifyContent="center"
							height={70}
							alignItems="center"
						>
							<RouterLink to="/">
								<Logo size="small" className={classes.logo} />
							</RouterLink>
						</Box>

						<Box p={2}>
							<Box display="flex" justifyContent="center">
								<Avatar
									alt="User"
									className={classes.avatar}
									src={user?.avatar}
								/>
							</Box>

							<Box mt={2} textAlign="center">
								<Typography variant="h5" className={classes.headerTitle}>
									{`${user?.firstName} ${user?.lastName}`}
								</Typography>
							</Box>
						</Box>
					</Box>
				</Hidden>
				<Hidden lgDown>
					<Box p={2}>
						<Box display="flex" justifyContent="center">
							<RouterLink to="/app/account">
								<Avatar
									alt="User"
									className={classes.avatar}
									src={user?.avatar}
								/>
							</RouterLink>
						</Box>
						<Box mt={2} textAlign="center">
							<Link
								component={RouterLink}
								to="/app/account"
								variant="h5"
								color="textPrimary"
								underline="none"
							>
								{`${user?.name}`}
							</Link>
						</Box>
					</Box>
				</Hidden>

				<Divider />

				<Box p={2}>
					{navConfig.map(config =>
						userInfo.can(config.acl) ? (
							<List
								key={config.subheader}
								subheader={
									<ListSubheader disableGutters disableSticky>
										{config.subheader}
									</ListSubheader>
								}
							>
								{renderNavItems({
									items: config.items,
									pathname: location.pathname
								})}
							</List>
						) : null
					)}
				</Box>
			</PerfectScrollbar>
		</Box>
	);

	return (
		<>
			<Hidden lgUp>
				<Drawer
					anchor="left"
					classes={{ paper: classes.mobileDrawer }}
					onClose={onMobileClose}
					open={openMobile}
					variant="temporary"
				>
					{content}
				</Drawer>
			</Hidden>
			<Hidden mdDown>
				<Drawer
					anchor="left"
					classes={{ paper: classes.desktopDrawer }}
					open
					variant="persistent"
				>
					{content}
				</Drawer>
			</Hidden>
		</>
	);
}

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool
};

export default NavBar;
