/* eslint-disable react/no-array-index-key */
import React, { lazy } from 'react';

const systemRoutes = [
	{
		exact: true,
		path: '/app/system/users',
		component: lazy(() => import('./Users/List'))
	},
	{
		exact: true,
		path: '/app/system/users/create',
		component: lazy(() => import('./Users/Create'))
	},
	{
		exact: true,
		path: '/app/system/users/edit/:entityId',
		component: lazy(() => import('./Users/Edit'))
	},
	{
		exact: true,
		path: '/app/system/groups',
		component: lazy(() => import('./Groups/List'))
	},
	{
		exact: true,
		path: '/app/system/groups/create',
		component: lazy(() => import('./Groups/Create'))
	},
	{
		exact: true,
		path: '/app/system/groups/edit/:groupId',
		component: lazy(() => import('./Groups/Edit'))
	},
	{
		exact: true,
		path: '/app/system/configurations',
		component: lazy(() => import('./Configurations/List'))
	},
	{
		exact: true,
		path: '/app/system/tenants',
		component: lazy(() => import('./Tenants'))
	},
	{
		exact: true,
		path: '/app/system/tenants/create',
		component: lazy(() => import('./Tenants/Edit'))
	},
	{
		exact: true,
		path: '/app/system/tenants/edit',
		component: lazy(() => import('./Tenants/Edit'))
	},
	{
		exact: true,
		path: '/app/system/tenants/:tenantId/edit',
		component: lazy(() => import('./Tenants/Edit'))
	},
	{
		exact: true,
		path: '/app/system/workspaces',
		component: lazy(() => import('./Workspaces'))
	},
	{
		exact: true,
		path: '/app/system/workspaces/create',
		component: lazy(() => import('./Workspaces/Edit'))
	},
	{
		exact: true,
		path: '/app/system/workspaces/edit',
		component: lazy(() => import('./Workspaces/Edit'))
	},
	{
		exact: true,
		path: '/app/system/workspaces/:entityId/edit',
		component: lazy(() => import('./Workspaces/Edit'))
	},
	{
		exact: true,
		path: '/app/system/workspacesType',
		component: lazy(() => import('./WorkspacesType'))
	},
	{
		exact: true,
		path: '/app/system/workspacesType/create',
		component: lazy(() => import('./WorkspacesType/Edit'))
	},
	{
		exact: true,
		path: '/app/system/workspacesType/edit',
		component: lazy(() => import('./WorkspacesType/Edit'))
	},
	{
		exact: true,
		path: '/app/system/workspacesType/:entityId/edit',
		component: lazy(() => import('./WorkspacesType/Edit'))
	},
	{
		exact: true,
		path: '/app/system/tags/',
		component: lazy(() => import('./TagDocuments'))
	},
	{
		exact: true,
		path: '/app/system/tags/edit',
		component: lazy(() => import('./TagDocuments/Edit'))
	},
	{
		exact: true,
		path: '/app/system/tags/:entityId/edit',
		component: lazy(() => import('./TagDocuments/Edit'))
	},
	{
		exact: true,
		path: '/app/system/tags/create',
		component: lazy(() => import('./TagDocuments/Edit'))
	},
	{
		exact: true,
		path: '/app/system/reportdefinition/',
		component: lazy(() => import('./ReportDefinitions'))
	},
	{
		exact: true,
		path: '/app/system/reportdefinition/create',
		component: lazy(() => import('./ReportDefinitions/Edit'))
	},
	{
		exact: true,
		path: '/app/system/reportdefinition/edit',
		component: lazy(() => import('./ReportDefinitions/Edit'))
	},
	{
		exact: true,
		path: '/app/system/reportdefinition/:entityId/edit',
		component: lazy(() => import('./ReportDefinitions/Edit'))
	},
	{
		exact: true,
		path: '/app/system/reportparameter/',
		component: lazy(() => import('./ReportParameters'))
	},
	{
		exact: true,
		path: '/app/system/reportparameter/create',
		component: lazy(() => import('./ReportParameters/Edit'))
	},
	{
		exact: true,
		path: '/app/system/reportparameter/edit',
		component: lazy(() => import('./ReportParameters/Edit'))
	},
	{
		exact: true,
		path: '/app/system/reportparameter/:entityId/edit',
		component: lazy(() => import('./ReportParameters/Edit'))
	},
	{
		exact: true,
		path: '/app/system/templates/',
		component: lazy(() => import('./TemplateDocuments'))
	},
	{
		exact: true,
		path: '/app/system/templates/edit',
		component: lazy(() => import('./TemplateDocuments/Edit'))
	},
	{
		exact: true,
		path: '/app/system/templates/:entityId/edit',
		component: lazy(() => import('./TemplateDocuments/Edit'))
	},
	{
		exact: true,
		path: '/app/system/templates/create',
		component: lazy(() => import('./TemplateDocuments/Edit'))
	},
	{
		exact: true,
		path: '/app/system/ticketType',
		component: lazy(() => import('./TicketType'))
	},
	{
		exact: true,
		path: '/app/system/ticketType/create',
		component: lazy(() => import('./TicketType/Edit'))
	},
	{
		exact: true,
		path: '/app/system/ticketType/edit',
		component: lazy(() => import('./TicketType/Edit'))
	},
	{
		exact: true,
		path: '/app/system/ticketType/:entityId/edit',
		component: lazy(() => import('./TicketType/Edit'))
	},
	{
		exact: true,
		path: '/app/system/mailbox',
		component: lazy(() => import('./Mailbox'))
	},
	{
		exact: true,
		path: '/app/system/mailbox/create',
		component: lazy(() => import('./Mailbox/Edit'))
	},
	{
		exact: true,
		path: '/app/system/mailbox/edit',
		component: lazy(() => import('./Mailbox/Edit'))
	},
	{
		exact: true,
		path: '/app/system/mailbox/:entityId/edit',
		component: lazy(() => import('./Mailbox/Edit'))
	},
	{
		exact: true,
		path: '/app/system/storyboards',
		component: lazy(() => import('./Storyboards'))
	},
	{
		exact: true,
		path: '/app/system/storyboards/create',
		component: lazy(() => import('./Storyboards/Edit'))
	},
	{
		exact: true,
		path: '/app/system/storyboards/edit',
		component: lazy(() => import('./Storyboards/Edit'))
	},
	{
		exact: true,
		path: '/app/system/storyboards/:entityId/edit',
		component: lazy(() => import('./Storyboards/Edit'))
	},
	{
		exact: true,
		path: '/app/system/storyboards/:entityId/permission/create',
		component: lazy(() => import('./Storyboards/Edit/Permission/Edit/Permission'))
	},
	{
		exact: true,
		path: '/app/system/storyboards/:entityId/permission/:permissionId/view',
		component: lazy(() => import('./Storyboards/Edit/Permission/Edit/Permission'))
	},
	{
		exact: true,
		path: '/app/system/migrations',
		component: lazy(() => import('./Migration'))
	},
	{
		exact: true,
		path: '/app/system/console',
		component: lazy(() => import('./Administration/ConsolePython')
		)
	},
	{
		exact: true,
		path: '/app/system/system',
		component: lazy(() => import('./System')
		)
	},
];

export default systemRoutes;
