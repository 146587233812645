import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';
import { clearStoredSettings } from 'src/utils/settings';
import { URLS } from 'src/submodules/commons-system/constants/urls';
import appMobileService from './appMobile.service';
import bpartnerService from './bpartnerService';
import configService from './configService';
import tenantsService from './tenantService';
import Cookies from 'js-cookie';
import { getDomainName } from 'src/utils/common';
import refreshTokenService from 'src/submodules/commons-auth/Refresh/refreshService';

class AuthService {
	setAxiosInterceptors = ({ onLogout }) => {
		axios.interceptors.response.use(
			response => response,
			error => {
				if (error.response && error.response.status === 401) {
					this.setSession(null);

					if (onLogout) {
						onLogout();
					}
				}

				return Promise.reject(error);
			}
		);
	};

	handleAuthentication() {
		const accessToken = this.getAccessToken();

		if (!accessToken) {
			return;
		}

		if (this.isValidToken(accessToken)) {
			this.setSession(accessToken);
		} else {
			this.setSession(null);
		}
	}

	loginUsingCredentials = (username, password, mobile = null) =>
		new Promise((resolve, reject) => {
			axios
				.post(URLS.PROFILES.LOGIN, { username, password })
				.then(response => {
					if (response.data) {
						if (
							response.data.user?.tenants?.length > 0 ||
							response.data.user?.authorities?.find(
								x => x.id === 'ROLE_ADMINISTRATOR'
							)
						) {
							let accessToken = response.data.accessToken;
							let refreshToken = response.data.refreshToken;
							refreshTokenService.saveRefreshToken(refreshToken);
							if(mobile) {
								appMobileService.loginSuccess(accessToken, mobile);
								resolve();
							} else {
								this.setSession(accessToken);
								tenantsService.setTenants(response.data.user.tenants);
								configService.getConfig();
								this.loginUsingToken().then(res => {
									resolve(res);
								});
							}
						} else {
							reject("Non sei abilitato all'utilizzo del portale.");
						}
					} else {
						reject(response.data.error);
					}
				})
				.catch(error => {
					reject(error);
				});
		});

	loginUsingToken = () =>
		new Promise((resolve, reject) => {
			axios
				.get(URLS.PROFILES.ME)
				.then(response => {
					if (response.data.result) {
						tenantsService.setTenants(response.data.result.tenants);
						if(response.data.result?.extensions?.profiles && response.data.result?.extensions?.profiles?.length > 0){
							if (!bpartnerService.getBpartner())
								bpartnerService.setBpartner(
									response.data.result?.extensions?.profiles[0]?.bpartnerId
								);
							else 
								bpartnerService.setInterceptor();
						}

						resolve(response.data.result);
					} else {
						reject(response);
					}
				})
				.catch(error => {
					reject(error);
				});
		});

	lostPassword = email =>
		new Promise((resolve, reject) => {
			axios
				.get(`${URLS.PROFILES.RESET}?id=${email}`)
				.then(response => {
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});

	resetPassword = params =>
		new Promise((resolve, reject) => {
			axios
				.put(URLS.PROFILES.RESET, params)
				.then(response => {
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});

	logout = () => {
		this.setSession(null);
	};

	setSession = accessToken => {
		if (accessToken) {
			this.saveToken(accessToken);
		} else {
			this.removeToken();
			tenantsService.clearStorage();
			bpartnerService.clearStorage()
			clearStoredSettings();
			configService.getConfig();
		}
	};

	getAccessToken = () => Cookies.get('accessToken')

	isValidToken = accessToken => {
		if (!accessToken) {
			return false;
		}

		const decoded = jwtDecode(accessToken);
		const currentTime = Date.now() / 1000;

		return decoded.exp > currentTime;
	};

	isAuthenticated = () => !!this.getAccessToken();

	registration = params => {
		return axios.post(URLS.PROFILES.REGISTRATION, params);
	};

	completeRegistration = params => {
		return axios.put(URLS.PROFILES.REGISTRATION, params);
	};

	validateToken(resourceId, token) {
		let params = { resourceId, token };

		return axios.get(URLS.VALIDATE_TOKEN, { params: params });
	};


	downloadInvoice = tokenId =>
	new Promise((resolve, reject) => {
		axios
			.get(URLS.SMS_INVOICES+'/'+tokenId, { params: null })
			.then(response => {
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	});

	saveToken = accessToken => {
		localStorage.setItem('accessToken', accessToken);
		Cookies.set('accessToken', accessToken,{domain:getDomainName()});
		axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
	};

	removeToken = () =>  {
		Cookies.remove('accessToken',{domain:getDomainName()});
		localStorage.removeItem('accessToken');
		delete axios.defaults.headers.common.Authorization;
	};
	
}

const authService = new AuthService();

export default authService;
