import React, { useState, useRef, useEffect } from 'react';
import {
	Badge,
	Box,
	Button,
	IconButton,
	Popover,
	SvgIcon,
	TextField,
	Tooltip,
	Typography,
	makeStyles
} from '@material-ui/core';
import { Settings as SettingsIcon } from 'react-feather';
import useSettings from 'src/hooks/useSettings';
import { THEMES } from 'src/submodules/commons-misc/constant'
import tenantsService from 'src/services/tenantService';
import { useTranslation} from "react-i18next";
import { TRANSLATE } from 'src/submodules/commons-misc/constant';

const useStyles = makeStyles((theme) => ({
	badge: {
		height: 10,
		width: 10,
		borderRadius: 5,
		marginTop: 10,
		marginRight: 5
	},
	popover: {
		width: 320,
		padding: theme.spacing(2)
	}
}));

function Settings() {
	const classes = useStyles();
	const ref = useRef(null);
	const { settings, saveSettings } = useSettings();
	const [isOpen, setOpen] = useState(false);
	const [values, setValues] = useState({
		direction: settings.direction,
		responsiveFontSizes: settings.responsiveFontSizes,
		theme: settings.theme,
		language : settings.language,
		timezone: settings.timezone
	});
	const tenants = tenantsService.getTenants();
	const [tenant, setTenant] = useState(tenantsService.getCurrentTenant()?.id || tenants?.[0]?.id);
	const themeOptions = getThemes();
	const { t, i18n } = useTranslation();

	function getThemes() {
		let result = {};

		Object.keys(THEMES).map((key) => {
			let theme = THEMES[key];
			let tntTheme = tenantsService.getCurrentTenant()?.properties?.props?.thema;

			if(theme?.parent) {
				if(tntTheme && tntTheme === theme?.name) {
					delete result[theme.parent];

					result[key] = theme;
				}
			} else {
				result[key] = theme;
			}

		})

		return result;
	}
	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleChange = (field, value) => {
		setValues({
			...values,
			[field]: value
		});
	};

	const handleSave = () => {
		
		saveSettings(values);
		setOpen(false);

		let t = tenants.find(x => x.id == tenant);
		tenantsService.setCurrentTenant(t);
	};

	return (
		<>
			<Tooltip title={t(TRANSLATE.SETTINGS)}>
				<Badge
					color="secondary"
					classes={{ badge: classes.badge }}
				>
					<IconButton
						color="inherit"
						onClick={handleOpen}
						ref={ref}
					>
						<SvgIcon fontSize="small">
							<SettingsIcon />
						</SvgIcon>
					</IconButton>
				</Badge>
			</Tooltip>
			<Popover
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				classes={{ paper: classes.popover }}
				anchorEl={ref.current}
				onClose={handleClose}
				open={isOpen}
			>
				<Typography
					variant="h4"
					color="textPrimary"
				>
					{t(TRANSLATE.SETTINGS)}
				</Typography>
				{/*
				<Box
					mt={2}
					px={1}
				>
					<FormControlLabel
						control={(
							<Switch
								checked={values.direction === 'rtl'}
								edge="start"
								name="direction"
								onChange={(event) => handleChange('direction', event.target.checked ? 'rtl' : 'ltr')}
							/>
						)}
						label="RTL"
					/>
				</Box>
					<Box
						mt={2}
						px={1}
					>
					<FormControlLabel
						control={(
							<Switch
								checked={values.responsiveFontSizes}
								edge="start"
								name="direction"
								onChange={(event) => handleChange('responsiveFontSizes', event.target.checked)}
							/>
						)}
						label="Responsive font sizes"
					/>
					</Box>
				*/}

				{/* THEME */}
				<Box mt={2}>
					<TextField
						fullWidth
						label={t(TRANSLATE.THEME)}
						name="theme"
						onChange={(event) => handleChange('theme', event.target.value)}
						select
						SelectProps={{ native: true }}
						value={values.theme}
						variant="outlined"
					>
						{Object.keys(themeOptions).map((key) => {
							let theme = THEMES[key];

							return (
								<option
									key={theme?.name}
									value={theme?.name}
								>
									{t(theme?.label)}
								</option>
							)
						})}
					</TextField>
				</Box>
			
				{/* TENANTS */}
				<Box mt={2}>
					<TextField
						fullWidth
						label="Tenant"
						name="tenant"
						onChange={(event) => setTenant(event.target.value)}
						select
						SelectProps={{ native: true }}
						value={tenant}
						variant="outlined"
					>
						{tenants?.map((t) => (
							<option
								key={t.id}
								value={t.id}
							>
								{t.name}
							</option>
						))}
					</TextField>
				</Box>
				<Box mt={2}>
					<Button
						variant="contained"
						color="secondary"
						fullWidth
						onClick={handleSave}
					>
						{t(TRANSLATE.SAVE)}
					</Button>
				</Box>
			</Popover>
		</>
	);
}

export default Settings;
