export const CONSTANT = {
	REQUIRED: 'Campo obbligatorio',
	NULLABLE: 'Campo obbligatorio',
	ACTION_ADD: 'Aggiungi',
	ACTION_CREATE: 'Crea',
	ACTION_SAVE: 'Salva',
	ACTION_DELETE: 'Cancella',
	REC_NEW: 'Nuovo',
	REC_UPDATE: 'Modifica',
	YEAR: 'Inserire anno corretto',
	EMAIL: 'Formato Email non corretto',
	NUMBER_POSITIVE: 'Inserire un valore positivo',
	NUMBER: 'Inserire un valore numerico',
	INTEGER: 'Inserire un valore intero',
	CURRENT_YEAR: new Date().getFullYear(),
	MOCK_DELAY: 1000,
	MOCK_ENABLED: false,
	STEP: 'STEP',
	INIT: 'INIT',
	DOCUMENT_REQUIRED: 'Inserisci il documento',
	PIC_REQUIRED: 'Inserisci la foto',
	FISCAL_CODE: 'Codice fiscale non corretto',
	VAT_CODE: 'Partita iva non corretta',
	WEB_APPS:'webApps'
};

export const TRANSLATE = {
	// TOPBAR
	APPLICATIONS:'APPLICATIONS',
	LANGUAGE:'LANGUAGE',
	SAVE:'SAVE',
	SETTINGS:'SETTINGS',
	THEME:'THEME',
	LIGHT_DEFAULT:'LIGHT_DEFAULT',
	DARK:'DARK',
	EDIT_ACCOUNT:'EDIT_ACCOUNT',
	LOGOUT:'LOGOUT',

	// PROFILE
	USER_SETTINGS:'USER_SETTINGS',
	PROFILE_INFO:'PROFILE_INFO',
	GENERAL:'GENERAL',
	NOTIFICATIONS:'NOTIFICATIONS',
	FUNCTIONS:'FUNCTIONS',
	PERMITS:'PERMITS',
	ROLES:'ROLES',
	PERMISSION_SUMMARY:'PERMISSION_SUMMARY',
	ATTENTION:'ATTENTION',
	PROFILE_DIALOG:'PROFILE_DIALOG',
	PROFILE_DIALOG2:'PROFILE_DIALOG2',
	PROCEED:'PROCEED',

	// GENERAL
	USER_DATA:'USER_DATA',
	GENERAL_ERROR:'GENERAL_ERROR',
	FIELD_REQUIRED:'FIELD_REQUIRED',
	USERNAME_REQUIRED:'USERNAME_REQUIRED',
	EMAIL_ERROR:'EMAIL_ERROR',
	EMAIL_REQUIRED:'EMAIL_REQUIRED',
	FIRST_NAME:'FIRST_NAME',
	FIRST_NAME_REQUIRED:'FIRST_NAME_REQUIRED',
	LAST_NAME:'LAST_NAME',
	LAST_NAME_REQUIRED:'LAST_NAME_REQUIRED',
	VISUALIZED_NAME:'VISUALIZED_NAME',
	VISUALIZED_NAME_REQUIRED:'VISUALIZED_NAME_REQUIRED',
	ROLE:'ROLE',
	PHONE:'PHONE',
	NOTE:'NOTE',
	ACTIVE:'ACTIVE',
	CREATE:'CREATE',
	USER_CHANGED:'USER_CHANGED',
	RESET_PASSWORD:'RESET_PASSWORD',
	NEW_PASSWORD:'NEW_PASSWORD',
	PASSWORD_REQUIRED:'PASSWORD_REQUIRED',
	CONFIRM_PASSWORD:'CONFIRM_PASSWORD',
	PASSWORD_ERROR:'PASSWORD_ERROR',
	CHANGE_PASSWORD:'CHANGE_PASSWORD',
	PASSWORD_CHANGED:'PASSWORD_CHANGED',
	SUBMIT_RESET_PASSWORD:'SUBMIT_RESET_PASSWORD',
	PASSWORD_DIALOG:'PASSWORD_DIALOG',
	PASSWORD_DIALOG2:'PASSWORD_DIALOG2',
	CONFIRM:'CONFIRM',
	CANCEL:'CANCEL',

	// NOTIFICATION
	NOTIFICATIONS_:'NOTIFICATIONS_',
	NOTIFICATION_CHANGED:'NOTIFICATION_CHANGED',
	NOTIFICATIONS_INFO:'NOTIFICATIONS_INFO',

	// TENANT
	ADD_TENANT:'ADD_TENANT',
	TENANT_INFO:'TENANT_INFO',
	TENANT_CHANGED:'TENANT_CHANGED',
	ADD:'ADD',
	TENANT_LIST:'TENANT_LIST',
	TENANT_INFO2:'TENANT_INFO2',
	NO_TENANT:'NO_TENANT',
	EXTENSION_LIST:'EXTENSION_LIST',
	TENANT_INFO3:'TENANT_INFO3',
	NO_EXTENSION:'NO_EXTENSION',
	PROPERTY_LIST:'PROPERTY_LIST',
	TENANT_INFO4:'TENANT_INFO4',
	NO_PROPERTY:'NO_PROPERTY',
	DELETE_TENANT:'DELETE_TENANT',
	TENANT_DIALOG:'TENANT_DIALOG',
	DELETE_PROFILE:'DELETE_PROFILE',
	TENANT_DIALOG2:'TENANT_DIALOG2',
	DELETE_PROPERTY:'DELETE_PROPERTY',
	TENANT_DIALOG3:'TENANT_DIALOG3',

	// FUNCTIONS
	ADD_FUNCTION:'ADD_FUNCTION',
	FUNCTION_INFO:'FUNCTION_INFO',
	GROUP_CHANGED:'GROUP_CHANGED',
	FUNCTION:'FUNCTION',
	FUNCTION_LIST:'FUNCTION_LIST',
	FUNCTION_INFO2:'FUNCTION_INFO2',
	NO_FUNCTION:'NO_FUNCTION',
	DELETE_FUNCTION:'DELETE_FUNCTION',
	FUNCTION_DIALOG:'FUNCTION_DIALOG',

	// PERMITS
	PERMITS_INFO:'PERMITS_INFO',

	// ROLES
	ROLES_INFO:'ROLES_INFO',

	// PERMISSION SUMMARY
	GROUPS_:'GROUPS_',
	GROUPS:'GROUPS',
	FUNCTION_TAG:'FUNCTION_TAG',
	DESCRIPTION:'DESCRIPTION',
	KEY:'KEY',
	CREATE_:'CREATE_',
	READ:'READ',
	UPDATE:'UPDATE',
	DELETE:'DELETE',
	YES:'YES',
	FUNCTION_TAG2:'FUNCTION_TAG2',
	GROUPS_INFO:'GROUPS_INFO',

	// TABLE
	SORT:'SORT',
	FILTERS:'FILTERS',
	SEARCH:'SEARCH',
	CONTAINS:'CONTAINS',
	CONTAINS_:'CONTAINS_',
	GREATER_THAN_OR_EQUAL:'GREATER_THAN_OR_EQUAL',
	LESS_THAN_OR_EQUAL:'LESS_THAN_OR_EQUAL',
	EQUAL:'EQUAL',
	STARTS_WITH:'STARTS_WITH',
	STARTS_WITH_:'STARTS_WITH_',
	NO_RESULT_FOUNDED:'NO_RESULT_FOUNDED',
	SEARCH_RESULTS:'SEARCH_RESULTS',

	// FILTER
	NAME_AZ:'NAME_AZ',
	NAME_ZA:'NAME_ZA',
	DESCRIPTION_AZ:'DESCRIPTION_AZ',
	DESCRIPTION_ZA:'DESCRIPTION_ZA',
	FROM_TRIP:'FROM_TRIP',
	TO_TRIP:'TO_TRIP',
	FROM_CHARGE:'FROM_CHARGE',
	TO_CHARGE:'TO_CHARGE',
	DATE:'DATE',
	NAME:'NAME',
	GROUP:'GROUP',
	SYSTEM_ADMINISTRATOR:'SYSTEM_ADMINISTRATOR',
	SYSTEM_SUPERVISOR:'SYSTEM_SUPERVISOR',
	AGENCY_ROLE:'AGENCY_ROLE',
	AGENT_ROLE:'AGENT_ROLE',
	BACKOFFICE_ROLE:'BACKOFFICE_ROLE',
	STATUS:'STATUS',
	ACTIVE_:'ACTIVE_',
	INACTIVE_:'INACTIVE_',
	NEW:'NEW',
	ASSEMBLING_:'ASSEMBLING_',
	CREATED_:'CREATED_',
	DELIVERED_:'DELIVERED_',
	READY_:'READY_',
	CREATION_NEW:'CREATION_NEW',
	CREATION_OLD:'CREATION_OLD',
	EXPIRATION_NEW:'EXPIRATION_NEW',
	EXPIRATION_OLD:'EXPIRATION_OLD',
	LAST_CHANGE_NEW:'LAST_CHANGE_NEW',
	LAST_CHANGE_OLD:'LAST_CHANGE_OLD',
	OPEN_:'OPEN_',
	CLOSE_:'CLOSE_',
	DRAFT_:'DRAFT_',
	ASSIGNMENT:'ASSIGNMENT',
	ASSIGNED_TO_ME:'ASSIGNED_TO_ME',
	ALL_ASSIGNMENTS:'ALL_ASSIGNMENTS',
	LAST_NAME_ASSIGNEE:'LAST_NAME_ASSIGNEE',
	NAME_ASSIGNEE:'NAME_ASSIGNEE',
	LAST_NAME_REPORTER:'LAST_NAME_REPORTER',
	NAME_REPORTER:'NAME_REPORTER',
	ISSUED_DEPARTMENT:'ISSUED_DEPARTMENT',
	ASSIGNED_DEPARTMENT:'ASSIGNED_DEPARTMENT',
	CREATION_DATE:'CREATION_DATE',
	EXPIRY_DATE:'EXPIRY_DATE',
	MODIFICATION_DATE:'MODIFICATION_DATE',
	TYPOLOGY:'TYPOLOGY',
	CUSTOMER:'CUSTOMER',
	TICKET_NUMBER:'TICKET_NUMBER',
	PROCESSING_STATUS:'PROCESSING_STATUS',
	TO_WORK:'TO_WORK',
	TAKEN_IN_CHARGE:'TAKEN IN CHARGE',
	ALL:'ALL',
	STATUS_OPEN:'STATUS_OPEN',
	COMPLETED_:'COMPLETED_',
	TITLE:'TITLE',
	DEPARTMENT_NAME:'DEPARTMENT_NAME',
	ASSIGNED_MY_DEPARTMENT:'ASSIGNED_MY_DEPARTMENT',
	STATUS_CLOSE:'STATUS_CLOSE',

	// NAVBAR
	ACCESSES:'ACCESSES',
	USERS:'USERS',
	CONFIGURATIONS:'CONFIGURATIONS',
	CONFIGURATION:'CONFIGURATION',
	WORKFLOW_TYPE:'WORKFLOW_TYPE',
	WORKSPACE_TYPE:'WORKSPACE_TYPE',
	STORYBOARD_MIGRATION:'STORYBOARD_MIGRATION',
	ADMINISTRATION:'ADMINISTRATION',
	SYSTEM:'SYSTEM',
	
	// ACCESSES
	USER:'USER',
	ACTIONS:'ACTIONS',
	INACTIVE:'INACTIVE',
	VIEW:'VIEW',
	NO_TAG_ASSOCIATED:'NO_TAG_ASSOCIATED',
	IMPORT:'IMPORT',
	IMPORT_:'IMPORT_',
	CONFIRM_:'CONFIRM_',
	CANCEL_:'CANCEL_',
	UPDATE_COMPLETED:'UPDATE_COMPLETED',
	CLOSE:'CLOSE',
	TAGS_ASSOCIATED:'TAG_ASSOCIATED',

	// CONFIGURATION
	WORKFLOW_DELETED:'WORKFLOW_DELETED',
	DELETE_WORKFLOW:'DELETE_WORKFLOW',
	CONFIGURATION_DIALOG:'CONFIGURATION_DIALOG',

	// WORKSPACE
	ASSIGNED_TO:'ASSIGNED_TO',
	TYPE:'TYPE',
	ACTIVE_USER:'ACTIVE_USER',
	WORKSPACE_DELETED:'WORKSPACE_DELETED',
	DELETE_WORKSPACE:'DELETE_WORKSPACE',
	WORKSPACE_DIALOG:'WORKSPACE_DIALOG',

	// DEVELOP
	STORYBOARD_DELETED:'STORYBOARD_DELETED',
	DELETE_STORYBOARD:'DELETE_STORYBOARD',
	DEVELOP_DIALOG:'DEVELOP_DIALOG',
	DRAFT:'DRAFT',
	DEVELOP_ERROR:'DEVELOP_ERROR',
	CANCELLATION_DONE:'CANCELLATION_DONE',
	MIGRATION_DONE:'MIGRATION_DONE',
	STORYBOARD_TO_MIGRATE:'STORYBOARD_TO_MIGRATE',
	WORKFLOW_TO_MIGRATE_OLD:'WORKFLOW_TO_MIGRATE_OLD',
	WORKFLOW_TO_MIGRATE_NEW:'WORKFLOW_TO_MIGRATE_NEW',
	DO_MIGRATION:'DO_MIGRATION',

	// ADMINISTARTION
	RUN:'RUN',
	MANAGE_SYSTEM:'MANAGE_SYSTEM',
	CHECK_SYSTEM:'CHECK_SYSTEM',
	ADMINISTRATION_INFO:'ADMINISTRATION_INFO',
	VERIFY:'VERIFY',
	SYNC_PROVIDER:'SYNC_PROVIDER',
	ADMINISTRATION_INFO2:'ADMINISTRATION_INFO2',
	SYNC:'SYNC',
	RUN_WORKFLOW:'RUN_WORKFLOW',

	// NEW
	NEW_USER:'NEW_USER',
	USER_CREATED:'USER_CREATED',
	ROLE_DESCRIPTION:'ROLE_DESRIPTION',
	NEW_GROUP:'NEW_GROUP',
	GROUPID_REQUIRED:'GROUPID_REQUIRED',
	DESCRIPTION_REQUIRED:'DESCRIPTION_REQUIRED',
	GROUPS_ADDED:'GROUP_ADDED',
	GROUP_DATA:'GROUP_DATA',
	GROUP_ID:'GROUP_ID',
	CHANGE:'CHANGE',
	CHANGE_TENANT:'CHANGE_TENANT',
	NEW_TENANT:'NEW_TENANT',
	IMAGE_UPLOADED:'IMAGE_UPLOADED',
	TENANT_SAVED:'TENANT_SAVED',
	TENANT_NAME:'TENANT_NAME',
	CODE:'CODE',
	ENABLED:'ENABLED',
	ADMIN_USER:'ADMIN_USER',
	USER_PASSWORD:'USER_PASSWORD',
	VIEW_IMAGE:'VIEW_IMAGE',
	UPLOAD_IMAGE:'UPLOAD_IMAGE',
	CHANGE_WORKFLOW_TYPE:'CHANGE_WORKFLOW_TYPE',
	NEW_WORKFLOW_TYPE:'NEW_WORKFLOW_TYPE',
	WORKFLOW_TYPE_SAVED:'WORKFLOW_TYPE_SAVED',
	WORKFLOW_TYPE_NAME:'WORKFLOW_TYPE_NAME',
	MANDATORY_CUSTOMER:'MANDATORY_CUSTOMER',
	MANDATORY_SUPPLY:'MANDATORY_SUPPLY',
	HOURS_TO_DEADLINE:'HOURS_TO_DEADLINE',
	DETAIL:'DETAIL',
	WORKSPACE_SAVED:'WORKSPACE_SAVED',
	WORKSPACE_NAME:'WORKSPACE_NAME',
	ASSIGNED:'ASSIGNED',
	MAILBOX_NOTIFICATIONS:'MAILBOX_NOTIFICATIONS',
	CHANGE_WORKSPACE_TYPE:'CHANGE_WORKSPACE_TYPE',
	NEW_WORKSPACE_TYPE:'NEW_WORKSPACE_TYPE',
	WORKSPACE_TYPE_NAME:'WORKSPACE_TYPE_NAME',
	NOBODY:'NOBODY',
	WORKFLOW_UPLOADED:'WORKFLOW_UPLOADED',
	STORYBOARD_SAVED:'STORYBOARD_SAVED',
	HIBERNATE_CLASS:'HIBERNATE_CLASS',
	WORKFLOW_STATUS:'WORKFLOW_STATUS',
	STORYBOARD_DIALOG:'STORYBOARD_DIALOG',
	ON:'ON',
	AT:'AT',
	UPLOAD_BPMN:'UPLOAD_BPMN',

	// DETAIL
	ASSOCIATED_USERS:'ASSOCIATED_USERS',
	ASSOCIATED_PERMITS:'ASSOCIATED PERMITS',
	NO_ASSOCIATED_USER:'NO_ASSOCIATED_USER',
	NO_PERMITS_ASSOCIATED:'NO_PERMITS_ASSOCIATED',
	PERMIT_DELETED:'PERMITS_DELETED',
	ACTIVE_PERMITS:'ACETIVE_PERMITS',
	ASSOCIATE_PERMIT:'ASSOCIATE_PERMIT',
	PERMIT:'PERMIT',
	ASSOCIATED_ON:'ASSOCIATED_ON',
	NO_PERMIT_FOUNDED:'NO_PERMIT_FOUNDED',
	DELETE_PERMIT:'DELETE_PERMIT',
	DETAIL_DIALOG:'DETAIL_DIALOG',
	PERMIT_SAVED:'PERMIT_SAVED',
	ASSOCIATE:'ASSOCIATE',
	ENTER_USER:'ENTER_USER',
	ACTIVE_USERS:'ACTIVE_USERS',
	ASSOCIATE_USER:'ASSOCIATE_USER',
	NO_USER_FOUNDED:'NO_USER_FOUNDED',
	DELETE_USER:'DELETE_USER',
	DETAIL_DIALOG2:'DETAIL_DIALOG2',
	USER_SAVED:'USER_SAVED',
	MAILBOX_DELETED:'MAILBOX_DELETED',
	ACTIVE_MAILBOXES:'ACTIVE_MAILBOXES',
	ASSOCIATE_MAILBOX:'ASSOCIATE_MAILBOX',
	NO_MAILBOX_FOUNDED:'NO_MAILBOX_FOUNDED',
	DELETE_MAILBOX:'DELETE_MAILBOX',
	DETAIL_DIALOG3:'DETAIL_DIALOG3',
	START_WORKFLOW:'START_WORKFLOW',
	ASSIGNEE:'ASSIGNEE',
	FINAL_USER:'FINAL_USER',
	SUPPORT:'SUPPORT',
	END_WORKFLOW:'END_WORKFLOW',
	STORYBOARD_PERMISSION_DELETED:'STORYBOARD_PERMISSION_DELETED',
	TAKING_CHARGE_ALLOCATION:'TAKING_CHARGE_ALLOCATION',
	ASSOCIATED_TO:'ASSOCIATED_TO',
	UNINSTALLABLE:'UNINSTALLABLE',
	INSTANTIABLE:'INSTANTIABLE',
	ASSIGNABLE:'ASSIGNABLE',
	USABLE_BY_CONTACT:'USABLE_BY_CONTACT',
	INSTALLED:'INSTALLED'
}

export const CONSTANT_SERVICE = {
	OFFERS: 'offers',
	TAGS: 'tags',
	WORKFLOW: 'workflow'
};

export const STATUS = {
	OK: 200,
	ERROR: 500
};

export const MESSAGE = {
	SUCCESS_DOC_SAVE: 'Documenti correttamente salvati',
	SUCCESS_SAVE: 'Dati correttamente salvati',
	SUCCESS_EDIT: 'Dati correttamente aggiornati',
	ERROR: 'Ops! Qualcosa è andato storto'
};

export const FILES = {
	APE: 'ape-pre-intervento',
	CI: 'documento-identita',
	CF: 'codice-fiscale',
	EDIFICIO_EXT: 'edificio-esterno',
	PORTE: 'porte-interne',
	FINESTRE: 'finestre',
	SCHEDA_CALDAIA: 'scheda-tecnica-caldaia',
	CALDAIA: 'caldaia',
	MURI: 'muri',
	ELEMENTI_RADIANTI: 'elementi-radianti',
	ELEMENTO_AUTORISCALDANTE: 'elemento-autoriscaldante',
	BOLLETTA_E: 'bolletta-energia-elettrica',
	BOLLETTA_G: 'bolletta-combustibile',
	VISURA: 'visura-catastale',
	PLANIMETRIA: 'planimetria',
	CONSENSO: 'consenso-gdpr',
	AGIBILITA: 'agibilita',
	ABUSI: 'abusi',
	VINCOLI: 'vincoli',
	LAVORI_EXTRA: 'lavori-extra',
	DISEGNI_PROGETTO: 'disegni-progetto',
	PROGETTAZIONE: 'progettazione',
	VARIE: 'varie',
	PROGETTO_PRELIMINARE: 'progetto-preliminare',
	PROGETTO_ESECUTIVO: 'progetto-esecutivo',
	ALTRI_DOCUMENTI: 'altri-documenti',
	TAVOLE: 'tavole',
	RELAZIONE: 'relazione',
	COMPUTO_METRICO: 'computo-metrico',
	STRATIGAFIA_CALCOLI: 'stratigrafia-e-calcoli',
	ANTI_MAFIA: 'attestato-anti-mafia',
	ATTESTAZIONE_SOA: 'attestazione-soa',
	ASSENZA_CARICHI_PENALI: 'dichiarazione-assenza-carichi-penali',
	ASSENZA_CARICHI_TRIBUTARI: 'dichiarazione-assenza-carichi-tributari',
	DURC: 'durc',
	GDPR: 'gdpr',
	ATTO_PROPRIETA: 'atto-propr',
	LAVORI_RISTRUTTURAZIONE: 'lavori-ristrutturazione',
	DOCUMENTO_FIRMATO_CONTRATTO: 'doc-contr-sign',
	DOCUMENTO_FIRMATO_PRE_CONTRATTO: 'doc-pre-contr-sign',
	ASSEVERAZIONI: 'asseverazioni',
	TABELLE_MILLESIMALE: 'tab-millesimale-prop',
	REGOLAMENTO_CONDOMINIO: 'regolamento-condominio',
	ATTO_COSTITUZIONE_CONDOMINIO: 'atto-costituzione-condominio',
	REGISTRO_ANAGRAFICA: 'registro-anagrafica',
	PRATICHE_EDILIZIE: 'pratiche',
	CI_COMM: 'documento-identita-committente',
	CF_COMM: 'codice-fiscale-committente',
	CONSENSO_PROPRIETARIO: 'consenso-propr',
};

export const TABS = {
	WORKFLOW: 'workflow',
	INDEX: 'index',
	OWNER: 'owner',
	CONDOMINIUM: 'condominium',
	HOUSING: 'housing',
	GENERAL: 'general',
	SPECIFIC: 'specific',
	FIXTURES: 'fixtures',
	INSTALLATIONS: 'installations',
	CONTRACTS: 'contracts',
	OTHER: 'other',
	ATTACHMENTS: 'attachments',
	BACKOFFICE: 'backoffice',
	DETAILS:'details'
};

export const CONSTANT_KEYS = {
	BE_APP_NAME: 'appName',
	BE_APP_ICON: 'appIcon',
	BE_GMAPS_KEY: 'appGoogleMapsApiKey',
	BE_AGENCY_STYLE: 'clientAgencyStyle',
	BE_CONTRACT_MULTILINE: 'appContractMultiline',
	BE_CONTRACT_SUPPLY_INVOICE: 'appContractSupplyInvoice',
	BE_CONTRACT_AGENCY_MODE: 'appContractAgencyMode',
	BE_USERNAME_GENERATION: 'usernameGeneration',
	BE_RELATIONS_CUSTOMER_TYPE_TYPOLOGY_USE:
		'appRelationsCustomerTypeTypologyUse',
	BE_ECOBONUS_GDPR: 'appEcobonusGdpr',
	ASSIGN_WORKSPACE_AGENTS: 'assignWorkspaceForAgents',
	CONTRACT: 'contract',
	PRECONTRACT: 'precontract',
	BPARTNER: 'bpartner',
	APP_CODE: 'appCode',
	PRIVACY_URL: 'appPrivacyUrl',
	CUSTOMER_TRANSLATION:'customerTranslation',
	URL_GOOGLE_PLAY_APP:'urlGoogleApp',
	URL_APPLE_APP:'urlAppleApp',
	TIMER_REFRESH_TOKEN:'timerTokenRefresh'
};
export const TYPES_DEVICE_ALL = [];

export const TYPES_ELECTRIC_DEVICE = [
    {
        label: 'Vin',
        name: 'code',
        type: 'text'
    }
    
];

export const TYPES_ELECTRIC_TRIP = [

	{
        label: 'Vin',
        name: 'code',
        type: 'text'
    }

];

export const TYPES_ELECTRIC_TRIP_DETAIL = [

	{
        label: TRANSLATE.FROM,
        name: 'from',
        type: 'date'
    },
	{
        label: TRANSLATE.TO,
        name: 'to',
        type: 'date'
    }
    
];

export const TYPES_ELECTRIC_RECHARGES = [

	{
        label: 'Vin',
        name: 'code',
        type: 'text'
    }

];

export const TYPES_ELECTRIC_RECHARGES_DETAIL = [

	{
        label: TRANSLATE.FROM,
        name: 'from',
        type: 'date'
    },
	{
        label: TRANSLATE.TO,
        name: 'to',
        type: 'date'
    }

];

export const TYPES_ELECTRIC_DIAGNOSTICS = [
 
	{
        label: 'VIN',
        name: 'code',
        type: 'text'
    }
    
];

export const TYPES_ELECTRIC_DIAGNOSTICS_DETAIL = [

	{
        label: TRANSLATE.DATE,
        name: 'date',
        type: 'date'
    }
    
]

export const TYPES_ELECTRIC_MAINTENANCE = [
    {
        label: 'Name',
        name: 'name',
        type: 'text',
    },
    {
        label: 'Imei',
        name: 'imei',
        type: 'text'
    },
	{
        label: 'Serial Number',
        name: 'serialNumber',
        type: 'text'
    },
	{
        label: 'Service Date',
        name: 'serviceDate',
        type: 'date'
    },
	{
        label: 'Expire Date',
        name: 'expireDate',
        type: 'date'
    }
];

export const TYPES_ELECTRIC_MAINTENANCE_DETAIL = [
    {
        label: 'Imei',
        name: 'imei',
        type: 'text'
    },
	{
        label: 'Serial Number',
        name: 'serialNumber',
        type: 'text'
    },
	{
        label: 'Service Date',
        name: 'serviceDate',
        type: 'date'
    },
	{
        label: 'Expire Date',
        name: 'expireDate',
        type: 'date'
    }
];

export const ALARMS = [
	{
		label: 'Severity',
		name: 'severity',
		type: 'enum',
		values: [
			{
				label: 'MAJOR',
				code: '0'
			},
			{
				label: 'LOW',
				code: '1'
			}
		]
	}
];

export const TYPES_FUNCTIONDEFINITION = [
	{
		label: TRANSLATE.DESCRIPTION,
		name: 'description',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: TRANSLATE.KEY,
		name: 'key',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	}
];

export const TYPES_GROUPS = [
	{
		label: TRANSLATE.NAME,
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	}
];

export const TYPES_MAILBOX = [
	{
		label: 'Nome',
		name: 'spotlight',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Stato',
		name: 'enabled',
		type: 'enum',
		values: [
			{
				label: 'ATTIVO',
				code: true
			},
			{
				label: 'NON ATTIVO',
				code: false
			}
		]
	}
];

export const TYPES_STORYBOARD = [
	{
		label: TRANSLATE.NAME,
		name: 'spotlight',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: TRANSLATE.DESCRIPTION,
		name: 'description',
		type: 'text'
	},
	{
		label: TRANSLATE.STATUS,
		name: 'status',
		type: 'enum',
		values: [
			{
				label: TRANSLATE.ACTIVE_,
				code: 'ACTIVE'
			},
			{
				label: TRANSLATE.INACTIVE_,
				code: 'INACTIVE'
			},
			{
				label: TRANSLATE.DRAFT_,
				code: 'DRAFT'
			}
		]
	}
];

export const TYPES_REPORT_DEFINITIONS = [
	{
		label: 'Codice',
		name: 'code',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Nome',
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Data inserimento',
		name: 'created',
		type: 'date'
	},
	{
		label: 'Data modifica',
		name: 'modified',
		type: 'date'
	},
	{
		label: 'Creato da',
		name: 'createdBy',
		type: 'text'
	},
	{
		label: 'Modificato da',
		name: 'modifiedBy',
		type: 'text'
	}
];

export const TYPES_REPORT_PARAMETERS = [
	{
		label: 'Codice',
		name: 'code',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Nome',
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Definizione',
		name: 'definition',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Data inserimento',
		name: 'created',
		type: 'date'
	},
	{
		label: 'Data modifica',
		name: 'modified',
		type: 'date'
	},
	{
		label: 'Creato da',
		name: 'createdBy',
		type: 'text'
	},
	{
		label: 'Modificato da',
		name: 'modifiedBy',
		type: 'text'
	}
];

export const TYPES_TENANT = [
	{
		label: TRANSLATE.NAME,
		name: 'spotlight',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: TRANSLATE.DESCRIPTION,
		name: 'description',
		type: 'text'
	},
	{
		label: TRANSLATE.STATUS,
		name: 'enabled',
		type: 'enum',
		values: [
			{
				label: TRANSLATE.ACTIVE_,
				code: true
			},
			{
				label: TRANSLATE.INACTIVE_,
				code: false
			}
		]
	}
];

export const TYPES_WORKSPACE = [
	{
		label: TRANSLATE.NAME,
		name: 'spotlight',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: TRANSLATE.DESCRIPTION,
		name: 'description',
		type: 'text'
	},
	{
		label: TRANSLATE.STATUS,
		name: 'enabled',
		type: 'enum',
		values: [
			{
				label: TRANSLATE.ACTIVE_,
				code: true
			},
			{
				label: TRANSLATE.INACTIVE_,
				code: false
			}
		]
	}
];

export const TYPES_TAG = [
	{
		label: 'Nome',
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	}
];

export const TYPES_TEMPLATE = [
	{
		label: 'Nome',
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	}
];

export const TYPES_USER = [
	{
		label: TRANSLATE.NAME,
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Email',
		name: 'mail',
		type: 'text'
	},
	{
		label: 'UserId',
		name: 'id',
		type: 'text'
	},
	{
		label: TRANSLATE.GROUP,
		name: 'group',
		type: 'text'
	},
	{
		label: TRANSLATE.ROLE,
		name: 'auth',
		type: 'enum',
		values: [
			{ code: 'ROLE_USER', label: TRANSLATE.USER },
			{ code: 'ROLE_ADMINISTRATOR', label: TRANSLATE.SYSTEM_ADMINISTRATOR },
			{ code: 'ROLE_SUPERVISOR', label: TRANSLATE.SYSTEM_SUPERVISOR },
			{ code: 'ROLE_AGENCY', label: TRANSLATE.AGENCY_ROLE },
			{ code: 'ROLE_AGENT', label: TRANSLATE.AGENT_ROLE },
			{ code: 'ROLE_BACKOFFICE', label: TRANSLATE.BACKOFFICE_ROLE }
		]
	},
	{
		label: TRANSLATE.STATUS,
		name: 'enabled',
		type: 'enum',
		values: [
			{
				label: TRANSLATE.ACTIVE_,
				code: true
			},
			{
				label: TRANSLATE.INACTIVE_,
				code: false
			}
		]
	}
];
