/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { colors, createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import typography from './typography';
import { softShadows, strongShadows } from './shadows';
import { THEMES } from 'src/submodules/commons-misc/constant'
import { itIT } from '@material-ui/core/locale';

const baseConfig = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
};

const themeConfigs = [
  {
    name: THEMES.LIGHT_DEFAULT.name,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      },
      MuiButton: { 
        containedSecondary:
          {
            backgroundColor: '#5850EC',
            color:'#FFFFFF'
          }
        }
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600],
        hover: 'rgba(0, 0, 0, 0.04)',
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white
      },
      primary: {
        main: colors.indigo[600]
      },
      secondary: {
        main: '#5850EC'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      },
      chart:{
        color:'rgba(244, 246, 248, 0.85)'
      },
      appBar:{
        hover: 'rgba(0, 0, 0, 0.04)',
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.DARK.name,
    overrides: {
      MuiButton: { 
        containedSecondary:
          {
            backgroundColor: '#5850EC',
            color: '#FFFFFF',
          }
        }
    },
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34'
      },
      primary: {
        main: '#8a85ff'
      },
      secondary: {
        main: '#8a85ff'
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb'
      },
      chart:{
        color:'rgba(28, 32, 37, 0.85)'
      },
      appBar:{
        hover: 'rgba(255, 255, 255, 0.04)',
      }
    },
    shadows: strongShadows
  },
  {
    name: THEMES.POST.name,
    overrides: {
 
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#1AB8C5'
          }
        },
        root:
        {
          borderRadius: '48px' ,
        } ,
      },
      MuiInput: {
        input: {
          backgroundColor: '#FFFFFF',
        }
      },
      MuiOutlinedInput:
      {
        root:
        {
          backgroundColor: '#FFFFFF',
          borderRadius: '48px' ,
        }  
      },
      MuiListItem:
      {
        button:{ "&:hover": {
          backgroundColor: '#ECECED'
        }}
      },
      MuiButton: { 
        root:
        {
          borderRadius: '48px' 
        } ,
        containedSecondary:
        {
          backgroundColor: '#1AB8C5',
          color: '#FFFFFF',
          borderRadius: '48px',
          textTransform: 'uppercase',
          "&:hover": {
            backgroundColor: '#1A8F9A'
          }
        } ,
        label:
        {
          textTransform: 'uppercase', 
          "&:hover": {
            color: '#525254'
          } 
        }
        },
        MuiListItemText:
        {
          root:
          {
            color: '#2B2D2B',
            "&:hover": {
              color: '#525254'
            }  
          }
        }, 
        MuiIconButton:
        {
        root:
        {
          color: '#2B2D2B',
          "&:hover": {
            color: '#525254'
          }  
        },
        label:
        {
          "&:hover": {
             color: '#1A8F9A' 
          }  
        }
        },
      MuiSvgIcon:
      {
        colorAction:
          {
            color: '#1A8F9A' 
          }
        }
        ,
      MuiCardHeader: {
        root: {
          backgroundColor:'#FFFFFF'  ,
        }, 
        title: {
          color: '#2D2B37',
        } 
      }, 
      MuiCard : {
        root: {
          backgroundColor:'#ECECED'  ,
        } 
      }  ,
      MuiPaper : {
        root: {
          backgroundColor:'#ECECED'  ,
        } 
      },
      MuiSwitch:{
        switchBase: {
          color: "#1AB8C5"
        },
        colorSecondary: {
          "&$checked": {
            color: "#1AB8C5"
          }
        },
        colorPrimary: {
          "&.Mui-checked": {
            // Controls checked color for the thumb
            color: "#1AB8C5"
          }
        },
        track: {
          opacity: 0.2,
          backgroundColor: "#1AB8C5",
          "$checked$checked + &": {
            opacity: 0.7,
            backgroundColor: "#1AB8C5"
          }
        }
      },
    },
    palette: {
      type: 'light',
      action: {
        active: '#0077C8',
        hover: '#22B8C5',
      },
      background: {
        main: '#ECECED' ,
        dark: '#ECECED' ,
        default: '#ECECED' ,
        card: '#ECECED' ,
        list: '#ECECED' 
      },
      primary: {
        main: '#00000',
        contrastText: '#2D2B2D' 
      },
      secondary: {
        main: '#22B8C5'
      }, 
      text: {
        primary: '#2B2D2B',
        secondary: '#2B2D2B'
      },
      chart:{
        color:'#ffffff'
      },
      appBar:{
        hover: '#f2f2f3',
      }
    },
    shadows: softShadows
  },
];

export function createTheme(settings = {}) {
  let themeConfig = themeConfigs.find((theme) => theme.name === settings.theme);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    [themeConfig] = themeConfigs;
  }

  let theme = createMuiTheme(
    _.merge(
      {},
      baseConfig,
      themeConfig,
      { direction: settings.direction }
	),
	itIT
	);

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
