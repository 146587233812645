import axios from 'axios';
import { alertService } from 'src/services/alert.service';
import { CONSTANT } from './constant';

// const instance = axios.create();

var _instance;

var baseUrl = '';

if (!process.env.REACT_APP_API_URL.includes('http'))
	baseUrl = `${window.location.origin}`;

baseUrl += process.env.REACT_APP_API_URL;

console.log("Node is for", process.env.NODE_ENV);
console.log("API URL", process.env.REACT_APP_API_URL);
console.log("BASE URL", baseUrl);

if (process.env.REACT_APP_API_URL !== undefined) {
	_instance = axios.create({
		baseURL: baseUrl
	});
}
else {
	_instance = axios.create();
}

_instance.interceptors.response.use(response => {
	// Any status code that lie within the range of 2xx cause this function to trigger
	// Do something with response data
	// console.log("INTERCEPTOR RESPONSE", response)
	if(CONSTANT.MOCK_ENABLED) console.log("HTTP MOCK", response);

	if(response.status != 200) {
		// alert(response?.data?.message)
		alertService.create('Ops', response?.data?.message)	
	}
	return response;
}, error => {
	// Any status codes that falls outside the range of 2xx cause this function to trigger
	// Do something with response error
	console.error("Error on Axios interception:", error)
	// console.error("Error on Axios interception:", error.response)

	if(CONSTANT.MOCK_ENABLED) console.error("HTTP MOCK:", error.response)
			
	if(
		error?.response?.status != 404 &&
		error?.response?.status != 401
	) {
		// console.log('response', error.response);
		if(error.response?.config?.responseType !== 'blob') {
			alertService.create('Ops', error?.response?.data?.message || error?.response?.data || "Errore Generico")
		} else {
			alertService.create('Ops', "Errore Generico");
		}
			
	}

	return Promise.reject(error);
});

const instance = _instance;

export default instance;


export function getBaseUrl () {
	return baseUrl;
}