/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import DesktopGuard from './components/Guards/DesktopGuard';
import AuthGuard from './components/Guards/AuthGuard';
import GuestGuard from './components/Guards/GuestGuard';
import AuthLayout from './submodules/commons-auth';
import systemRoutes from './submodules/commons-system/Routes';

const routesConfig = [
	{
		exact: true,
		path: '/',
		component: () => <Redirect to='/app' />
	},
	{
		exact: true,
		path: '/404',
		component: lazy(() => import('src/views/pages/Error404View'))
	},

	{
		exact: true,
		path: '/500',
		component: lazy(() => import('src/views/pages/Error500'))
	}
	,
	{
		path: '/auth',
		guard: GuestGuard,
		layout: AuthLayout,
		routes: [
			{
				exact: true,
				path: '/auth/login',
				component: lazy(() => import('src/submodules/commons-auth/Login'))
			},
			{
				component: () => <Redirect to="/auth/login" />
			}
		]
	}
	,{
		guard: AuthGuard,
		routes: [
			{
				path: '/app',
				guard: DesktopGuard,
				layout: DashboardLayout,
				routes: [
					{
						exact: true,
						path: [
							'/app'
						],
						component: lazy(() => import('./submodules/commons-system/Users/List'))
					},
					{
						exact: true,
						path: '/app/account',
						component: lazy(() => import('src/views/pages/AccountView'))
					},
					...systemRoutes,
					{
						component: () => <Redirect to="/404" />
					}
				]
			}
		]
	}
];

const renderRoutes = routes =>
	routes ? (
		<Suspense fallback={<LoadingScreen />}>
			<Switch>
				{routes.map((route, i) => {
					const Guard = route.guard || Fragment;
					const Layout = route.layout || Fragment;
					const Component = route.component;

					return (
						<Route
							key={i}
							path={route.path}
							exact={route.exact}
							render={props => (
								<Guard>
									<Layout>
										{route.routes ? (
											renderRoutes(route.routes)
										) : (
											<Component {...props} />
										)}
									</Layout>
								</Guard>
							)}
						/>
					);
				})}
			</Switch>
		</Suspense>
	) : null;

function Routes() {
	return renderRoutes(routesConfig);
}

export default Routes;
