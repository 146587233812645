import {
	AppBar,
	Box,
	Hidden,
	IconButton,
	makeStyles,
	SvgIcon, Toolbar
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Menu as MenuIcon } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/submodules/commons-misc/constant'
import CustomBreadcrumbs from './Breadcrumbs';

const useStyles = makeStyles(theme => ({
	root: {
		zIndex: theme.zIndex.drawer + 100,
		...(theme.name === THEMES.LIGHT_DEFAULT.name
			? {
					boxShadow: 'none',
					backgroundColor: theme.palette.primary.main
			  }
			: {}),
		...(theme.name === THEMES.DARK.name
			? {
					backgroundColor: theme.palette.background.default
			  }
			: {backgroundColor: theme.palette.background.default})
	},
	toolbar: {
		maxHeight: 64
	},
	logo: {
		width: '100%',
		height: '100%',
		maxHeight: 64,
		maxWidth: 200,
		padding: theme.spacing(1),
		objectFit: 'contain'
	}
}));

function TopBar({ className, onMobileNavOpen,items, ...rest}) {
	const classes = useStyles();
	return (
		<AppBar className={clsx(classes.root, className)} {...rest}>
			<Toolbar className={classes.toolbar}>
				<Hidden lgUp>
					<IconButton
						className={classes.menuButton}
						color="inherit"
						onClick={onMobileNavOpen}
					>
						<SvgIcon fontSize="small">
							<MenuIcon />
						</SvgIcon>
					</IconButton>
				</Hidden>
				<Hidden mdDown>
					<a href="https://www.ohmvehicles.com/">
						<Logo className={classes.logo} size="small" />
					</a>
				</Hidden>

				<Hidden xsDown smDown>
					<CustomBreadcrumbs />
				</Hidden>
				<Box ml={2} flexGrow={1} />
				{items && items?.map(item =>(
					item
				))}
			</Toolbar>
		</AppBar>
	);
}

TopBar.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func
};

export default TopBar;
