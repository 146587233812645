import React, { createRef, useEffect, useState } from 'react';
import { InfiniteLoader } from 'react-virtualized';
import layoutService from 'src/services/layoutService';
import { Header, VirtualizedTable } from './components';
import { PAGE_SIZE, REST_TIMER } from './config';
import { dataService, tableService } from './services';
import { useTranslation } from "react-i18next";
import { TRANSLATE } from 'src/submodules/commons-misc/constant';

function InfinityTable({
	rowHeight,
	reload,
	columns,
	dataSource,
	heightOffset,
	customCellRender,
	hideTableHeader,
	sort,
	filters,
	search,
	dataImport,
	dataExport,
	button,
	customActions = null,
	customContent = null,
	show = true,
	pageSize = null,
	orderFunction,
	orderedTable,
	isLoading,
	...rest
}) {
	const tableRef = createRef();
	const [allocatedData, setAllocatedData] = useState([]);
	const [lastParams, setLastParams] = useState({});
	const [loader, setLoader] = useState(true);
	const [isInit, setIsInit] = useState();
	const [start, setStart] = useState(null);
	const [stop, setStop] = useState(null);
	const { t } = useTranslation();
	const sizeList = pageSize !== null ? pageSize : PAGE_SIZE;

	var timer;

	if(!isInit) {
		let results = tableService.checkDefaultParams(sort, filters, search);

		setIsInit({ used: false, values: results });
	}

	function handleReload({
		startIndex = null,
		stopIndex = null,
		sortParams = null,
		filterParams = null,
		queryParams = null,
		reset = null
	}) {
		const str = startIndex != null ? startIndex : start;
		const stp = stopIndex || stop;
		var memory = allocatedData;

		if (reset) {
			memory = [];
			setLoader(true);
			setAllocatedData([]);
			if(tableRef.current){
				tableRef.current.tableRef.scrollToRow(0);
			}
		
		}

		const dataToLoad = tableService.getDataToLoad(str, stp, memory,sizeList); // return { start , count }

		if (
			(dataToLoad?.start !== lastParams?.start && dataToLoad?.count > 0) ||
			reset
		) {
			let newParams = {
				start: dataToLoad.start,
				count: dataToLoad.count,
				sort: sortParams?.sort || lastParams?.sort,
				filters: filterParams || lastParams?.filters || {},
				query: queryParams || lastParams?.query || {}
			};

			let paramsReload = {
				start: newParams.start,
				count: newParams.count,
				sort: newParams.sort,
				...newParams.filters,
				...newParams.query,
			}

			if (!isInit?.used) {
				paramsReload = {
					...paramsReload,
					sort: isInit?.values?.sort,
					...isInit?.values?.filters,
					...isInit?.values?.search
				};
				newParams = {
					...newParams,
					sort: isInit?.values?.sort,
					filters: isInit?.values?.filters,
					search: isInit?.values?.search
				}
				setIsInit({ used: true });
			}

			reload(paramsReload);
			setLastParams(newParams);
		}
	}

	function handleLoadMoreRows({ startIndex, stopIndex }) {
		clearTimeout(timer);

		timer = setTimeout(() => {
			setStart(startIndex);
			setStop(stopIndex);

			handleReload({ startIndex, stopIndex });
		}, REST_TIMER);
	}

	useEffect(() => {
		if (dataSource) {
			let newData = dataService.reallocate(
				dataSource.results,
				dataSource.start
			);

			let memory = dataService.manageMemory(allocatedData, dataSource.start,sizeList);
			memory = dataService.mergeArray(memory, newData);

			setAllocatedData(memory);
			setLoader(false);
			tableRef.current.tableRef.forceUpdateGrid();
		} else {
			setAllocatedData([]);
			setLoader(true);
		}
	}, [dataSource]);

	return (
		<React.Fragment>
			{(sort || search || button || dataExport || dataImport) &&
				!layoutService.isMobile() && (
					<Header
						sort={{
							options: sort?.options || sort, // sort.options per retrocompativbilità
							handleSort: res => {
								handleReload({ sortParams: res, reset: true });
							}
						}}
						filters={{
							options: filters,
							handleFilters: res => {
								handleReload({ filterParams: res, reset: true });
							}
						}}
						search={{
							...search,
							handleChange: res => {
								handleReload({ queryParams: res, reset: true })
							}
						}}
						dataImport={dataImport}
						dataExport={dataExport}
						button={button}
						params={lastParams}
						customActions={loader ? null : customActions}
					/>
				)}
			{show ? (
				<InfiniteLoader
					isRowLoaded={({ index }) => Boolean(allocatedData?.[index]) && !loader}
					loadMoreRows={handleLoadMoreRows}
					rowCount={dataSource ? dataSource.count : sizeList}
				>
					{({ onRowsRendered, registerChild }) => (
						<VirtualizedTable
							ref={r => {
								registerChild(r);
								tableRef.current = r;
							}}
							rowCount={dataSource ? dataSource.count : sizeList}
							rowGetter={({ index }) => allocatedData?.[index] || []}
							onRowsRendered={onRowsRendered}
							columns={columns}
							customCellRender={customCellRender}
							rowHeight={rowHeight}
							isLoading={loader}
							heightOffset={heightOffset}
							hideTableHeader={hideTableHeader}
							headerHeight={hideTableHeader && 0}
							searchResults={t(TRANSLATE.SEARCH_RESULTS)}
							noResult={t(TRANSLATE.NO_RESULTS_FOUND)}
							orderFunction={orderFunction}
							orderedTable={orderedTable ? orderedTable : false}
							load={isLoading}
						/>
					)}
				</InfiniteLoader>
			) : (
				customContent
			)}
		
		</React.Fragment>
	);
}

export default InfinityTable;
