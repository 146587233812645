import React, {
  createContext,
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { getStoredSettings, storeSettings } from 'src/utils/settings';
import configService from 'src/services/configService';
import Cookies from 'js-cookie';
import { THEMES } from 'src/submodules/commons-misc/constant';

const SettingsContext = createContext();


export function SettingsProvider({ settings, children }) {
  const settingsFromCookies = Cookies.get('settings') ? JSON.parse(Cookies.get('settings')) : null;

  let defaultSettings = {
      direction: 'ltr',
      responsiveFontSizes: true,
      theme: THEMES.LIGHT_DEFAULT.name,
      language:localStorage.getItem('i18nextLng')
  };

  if(settingsFromCookies){
    defaultSettings = null;
      defaultSettings = {
        ...settingsFromCookies
      };
  }

  localStorage.setItem('i18nextLng',defaultSettings?.language);

  const [currentSettings, setCurrentSettings] = useState(settings || getStoredSettings() || defaultSettings);
  const handleSaveSettings = (updatedSettings = {}) => {
    const mergedSettings = _.merge({}, currentSettings, updatedSettings);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  configService.getConfig();

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.object
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
